import UserMeta from "../../modules/UserMeta";
import Api from "../../modules/Api";
import Tour from "../../modules/Tour";
import Deus from "../../modules/Deus";
import GranterACL from "../../modules/Acl/GranterACL";

const Waves = require('node-waves');

const $ = require('jquery');

(function () {

    'use strict';

    //console.log("eccomi", $, jQuery);
    const app = angular.module('smara');

    const _hsq = window._hsq = window._hsq || [];


    function traceUser() {

        //return
        // Utente di sentry
        if (window.Sentry)
            window.Sentry.setUser({email: UserMeta.getInstance().userMeta.email});

        // utente su hubspot
        _hsq.push(["identify", {
            email: UserMeta.getInstance().userMeta.email
        }]);

    }

    app.run(['$rootScope', '$state', '$transitions', '$location', '$timeout', '$window', function ($rootScope, $state, $transitions, $location, $timeout, $window) {

        Deus.$on("goToState", function (event, data) {
            $state.go(data[0].state, data[0].params);
        });


        let fakeProgress;


        $transitions.onStart({}, function (trans) {
            //
            const to = trans.to()

            //
            if (to.externalUrl) {
                $window.open(to.externalUrl, to.target || '_self')
                return false
            }

            Deus.state.back = (trans.to().name === Deus.state.flow[Deus.state.flow.length - 2]);
            Deus.state.loading = true;
            Deus.state.progress = 0;

            fakeProgress = setInterval(function () {
                $timeout(function () {
                    Deus.state.progress += 2;
                })
            }, 50);

            if (!Deus.state.back)
                Deus.state.flow.push(trans.to().name);
            else {
                Deus.state.flow.pop();
            }

            Deus.state.history.push(trans.to().name);

            //console.log(trans.to())

        });

        $transitions.onSuccess({}, function (trans) {


            Deus.state.name = trans.to().name;
            Deus.clearTriggers()
            Deus.state.section = trans.to().data.section;
            Deus.state.hasSidebar = trans.to().data.hasSidebar;


            var capability = trans.params().capability,
                postID = trans.params().post ? trans.params().post.ID : -1;


            if (!Deus.state.fromSubview && !Deus.state.toSubview) {
                var targetY = 0;
                if ($(window).scrollTop() > 350) {
                    $timeout(function () {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: targetY
                        }, 400);
                        $(window).scrollTop(0);
                    }, 0);
                }
            }

            clearInterval(fakeProgress);

            $timeout(function () {
                Deus.state.progress = 100;
                $timeout(function () {
                    Deus.state.loading = false;
                    //Waves.attach('.btn', ['waves-button']);
                    Waves.init();
                    Waves.attach('.btn-primary', ['waves-float']);
                    Waves.attach('.btn-secondary', ['waves-float']);
                    Waves.attach('.btn-link');
                    Waves.attach('.sidebar-nav-item');
                    Waves.attach('.content-archive__card .card-body');
                    Waves.attach('#smara-private__topbar-icons-menu li');
                    Waves.attach('.btn-secondary');

                }, 600)
            });

            Tour.activateStateTimers();

            // popover tour

            // utilizzo un timeout per evitare di registrare il vecchio path anziché il nuovo
            $timeout(function () {

                // Google GA Tracking
                if (window.ga) {
                    window.ga('send', 'pageview', window.location.pathname);
                }

                // Hubspot Tracking
                _hsq.push(['setPath', window.location.pathname]);
                _hsq.push(['trackPageView']);

            })


            setTimeout(() => {
                // Kibana tracking
                var from = trans.params('from').d ? trans.params('from').d : trans.from().name;
                var to = trans.params('to').d ? trans.params('to').d : trans.to().name;
                Api.logChangeState(from, to);
            }, 300)

            //Api.logChangeState(trans.from().name, trans.to().name);


        });

        $transitions.onBefore({}, function (transition) {
            const stateService = transition.router.stateService;

            /**
             * Capability redirect check
             * Se esiste un redirect per la capability, lo esegue
             * Se esiste, è perché la membership dell'utente non può accedere a quella capability
             */
            /** @type {GranterCapabilities|null} */
            const capability = transition.params().capability ?? null;
            /** @type {MembershipType} */
            const membership = UserMeta.getInstance().userProduct.product.name;
            if(capability) {
                const {getCapabilityRedirect} = GranterACL();
                const redirectState = getCapabilityRedirect(membership,capability);
                if(redirectState) {
                    return stateService.target(redirectState);
                }
            }



            //console.log(transition.$to().parent.name);
            Deus.state.subview = (transition.$to().parent && transition.$to().parent.name !== "") && transition.$to().parent.name !== 'upgrade';

            Deus.state.toSubview = (transition.$to().parent && transition.$to().parent.name !== "");
            Deus.state.fromSubview = (transition.$from().parent && transition.$from().parent.name !== "");

            if (angular.isString(transition.to().abstract)) {
                return stateService.target(transition.to().abstract);
            }


        });


        traceUser();
        // log degli open link

        $('body').on("click", "a[target=_blank]", function () {

            const copy = this.title !== "" ? this.title : $(this).text();

            const id = $('#smara-subview__content-wrapper').data('post-id');
            //console.log(id);

            const info = {url: this.href, copy: copy, id: id};

            if (this.hostname !== location.hostname) {
                //console.log(info);
                Api.logEvent('ux', "outbound-link", info);
            }

        });


    }]);

})();