export type ACLFeature = {
    access: boolean;
    message?: string;
    redirectState?: string;
    requirements?: {
        affinity?:{
            [key: string]: string
        }
    }
}

export type ACLCapability = {
    message: string;
    redirectState: string;
}

export type ACLMembershipRules = {
    capabilities: {
        [key: string]: ACLCapability
    },
    menu: {
        [key: string]: boolean
    },
    features: {
        [key in Features]: ACLFeature
    },
    decorations?: {
        [key: string]: object
    }
}

export type ACLRules = {
    [key: string]: ACLMembershipRules
}

export enum GranterCapabilities {
    SCHEDA_BENEFATTORE = 'scheda_benefattore',
    SERP_BENEFATTORI = 'serp_benefattori',
    SCHEDA_OPPORTUNITA = 'scheda_opportunita',
    SERP_OPPORTUNITA = 'serp_opportunita',
    NEWS = 'news',
    TOOLS = 'tools',
    DATA_DRIVEN = 'data-driven',
    DAL_MONDO = 'dal-mondo',
    COMMUNITY = 'community',
    ANNOTATIONS = 'annotations'
}

export enum ACLStatusCode {
    OK = 200,
    UNAUTHORIZED = 403,
    MISSING_MEMBERSHIP = 411,
    MISSING_SUBSCRIPTION = 412,
    INACTIVE_SUBSCRIPTION = 413,
    NOT_IMPLEMENTED = 414,
    NOT_FOUND = 404,
    NOT_LOGGED_IN = 405,
    CSRF_ERROR = 406,
    INVALID_REQUEST = 400,
    NOT_SET = -1
}

export enum Features{
    DONOR_PAGE = 'donor_page',
    AUTOCOMPLETE = 'autocomplete',
    SERP_FILTERS = 'serp_filters',
    SAVE_SEARCH = 'save_search',
    PRIORITY = 'priority',
    FLAGS = 'flags',
    NOTE = 'note',
    PARTNERSHIP = 'partnership',
}