import Deus from "./Deus";

const axios = require('axios');
const _ = require('lodash');

import Config from "./Config";
import Logger from "./Logger";

class Api {


    static defaultParams = {
        get: {
            url: `${process.env.API_V1}/?rdm=` + new Date().getTime(),
            urlV2: `${process.env.API_V2}?rdm=` + new Date().getTime(),
            params: {}
        },
        post: {
            url: `${process.env.API_V1}/?rdm=` + new Date().getTime(),
            urlV2: `${process.env.API_V2}?rdm=` + new Date().getTime(),
            data: {}
        }
    };

    static errorHandling(error) {

        if (error.config.params)
            console.log(error.config.params.action, error.response.status);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            //Deus.$emit('openModal', {key: 'app:unauthorized'})
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error.config);

        throw error;
    }

    static logEvent(preference, action, info) {
        return Logger.logEvent(preference, action, info)
    }

    static logChangeState(from, to) {
        return Logger.logChangeState(from, to)
    }


    /**
     *
     * @param {string} securityKey
     */
    static setSecurity(securityKey) {
        if (!securityKey) return;
        _.forEach(this.defaultParams, function (value, key) {

            if (value.params)
                value.params.security = securityKey

            if (value.data)
                value.data.security = securityKey
        });
    };

    /**
     *
     * @param {object} customParameters
     * @returns {*}
     */


    static getAxiosInstance() {
         return axios;
    }


    static get(customParameters, hidden = false) {

        var parameters;
        parameters = _.extend({}, {
            security: Config.options.ajax_nonce,
        }, this.defaultParams.get.params, customParameters);
        //console.log(parameters);
        return Api.getAxiosInstance()({
            withCredentials: true,
            method: 'GET',
            url: this.defaultParams.get.url,
            dataType: 'json',
            params: parameters,
            progressBar : !hidden
            //withCredentials: true,
        })
            .catch((error) => {
                this.errorHandling(error)
            })
    };

    /**
     *
     * @param {object} customParameters
     * @returns {*}
     */
    static getV2(customParameters, hidden = false) {
        var parameters;

        parameters = _.extend({}, this.defaultParams.get.params, customParameters);
        //console.log(parameters);
        return Api.getAxiosInstance()({
            withCredentials: true,
            method: 'GET',
            url: this.defaultParams.get.urlV2,
            dataType: 'json',
            params: parameters,
            progressBar : !hidden
        }).catch((error) => {
                this.errorHandling(error)
            }
        )
    };

    /**
     *
     * @param {object} customData
     * @returns {*}
     */
    static postV2(customData, hidden = false) {

        const data = _.extend({}, this.defaultParams.post.data, customData);
        //console.log(parameters);


        var formData = new FormData();

        for (var key in data) {
            formData.append(key, data[key])
        }
        return Api.getAxiosInstance().post(this.defaultParams.post.urlV2, formData, {
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data'},
            cache: false,
            progressBar : !hidden
        }).catch((error) => {
                this.errorHandling(error)
            }
        )
        /*.then(function (response) {
        return response;
        });*/
    };

    /**
     *
     * @param {object} customData
     * @returns {*}
     */
    static post(customData, hidden = false) {
        var data;
        data = _.extend({}, {
            security: Config.options.ajax_nonce,
        }, this.defaultParams.post.data, customData);

        var formData = new FormData();

        for (var key in data) {
            formData.append(key, data[key])
        }
        return Api.getAxiosInstance().post(this.defaultParams.post.url, formData, {
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data'},
            cache: false,
            progressBar : !hidden
        }).catch((error) => {
                this.errorHandling(error)
            }
        )
    };


    /**
     *
     * @param {int} id
     * @returns {*}
     */
    static getPostFromId(id) {
        return Api.getV2({
            "post_id": id,
            action: 'get_post_from_id'
        }).catch((error) => {
                this.errorHandling(error)
            }
        );
    }
}

export default Api;