const _ = require('lodash');
import Api from './Api';

class Config {

    #options = {};

    getConfiguration() {

        if (this.options && Object.keys(this.options).length > 0)
            return this.options;

        return Api.get({
            action: 'get_php_vars',
            type: 'private'
        })
    }

    constructor() {
        if (!Config._instance) {
            this.options = {};
            Config._instance = this;
        }

        //console.log(Config._instance)
        return Config._instance;
    }

    static getInstance() {
        return this._instance;
    }

    static get options() {
        return Config.getInstance().#options
    }

    static get myaccountPath() {
        return `${process.env.WP_URL}/my-account`
    }


    init() {
        return Config.getInstance().getConfiguration().then((response) => {

            this.#options = response.data.data;
            this.#options.pricingUrls = JSON.parse(this.#options.pricingUrls);
            //console.log(this.#options)




            this.#options.userMeta = (function (userMeta) {
                const onboardingFields = {
                    smara_onboarding_steps: {
                        validity: []
                    },
                    smara_area_geografica: [],
                    smara_focus_geografico: [],
                    smara_focus_geografico_internazionale: [],
                    smara_topic: [],
                    smara_trend_topic: [],
                    smara_legal_address: {},
                    smara_legal_status: [],
                    smara_enti_ammissibili_tipologie: (function (v) {
                        if (_.isString(v) && v.length === 0 || _.isUndefined(v) || _.isNull(v)){
                            userMeta.smara_enti_ammissibili_tipologie = [];
                            return [];
                        }
                    })(userMeta.smara_enti_ammissibili_tipologie),
                    smara_enti_ammissibili_qualifiche: [],
                    smara_beneficiari_plus: [],
                    smara_cause: [],
                    smara_tour: {
                        modals: {p: "s"},
                        tooltips: {p: "s"}
                    },
                    smara_onboarding_checklist: (function (checklist) {
                        if (_.isUndefined(checklist)) return {__dismissed: true};
                    })(userMeta.smara_onboarding_checklist)
                };

                return (function (fields) {
                    _.forEach(fields, function (value, key) {
                        if (_.isUndefined(userMeta[key]) || userMeta[key] === null) {
                            userMeta[key] = value;
                        }
                    });
                    return userMeta;
                })(onboardingFields);
            })(this.#options.userMeta);
            // fix per la merge di smara_tour che non funziona
            if (_.isUndefined(this.#options.userMeta.smara_tour) || _.isArray(this.#options.userMeta.smara_tour)) this.#options.userMeta.smara_tour = {};
            if (_.isUndefined(this.#options.userMeta.smara_tour.tooltips) || _.isArray(this.#options.userMeta.smara_tour.tooltips)) this.#options.userMeta.smara_tour.tooltips = {};
            if (_.isUndefined(this.#options.userMeta.smara_tour.modals) || _.isArray(this.#options.userMeta.smara_tour.modals)) this.#options.userMeta.smara_tour.modals = {};

        })
    }

}


export default Config;