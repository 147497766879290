import Config from "./Config";
import Api from "./Api";
import _ from "lodash";
import UserMeta from "./UserMeta";
import PacksManager from "./Account/PacksManager";

import AdvisorStates from "./../staticModels/States/advisor.json";
import CustomerStates from "./../staticModels/States/customer.json";
import CustomSerpDecorations from "./Dictionary/CustomSerpDecorations";
import Utilities from "./Utilities";

class AppStates {
  static commons = {
    DONORS: {
      params: {
        showLimitAlert: false,
        capability: "serp_benefattori",
        serpType: "benefattori", // tipo di serp
        viewMode: "modal",
        page: {
          type: "int",
          value: 1,
          squash: true,
        },

        //searchSlug : { type : 'string'},
        q: {
          type: "json",
          value: {},
          squash: true,
        },
      },
    },
    DONORS_DETAIL: {
      resolve: {
        id: [
          "$transition$",
          function ($transition$) {
            //console.log(arguments)
            return $transition$.params().id;
          },
        ],
        post: [
          "$transition$",
          function ($transition$) {
            //console.log($transition$.params().permalink)
            var post = $transition$.params().post;

            if (post) {
              return AppStates.configPost(post, "publish", "light");
            } else {
              return Api.getPostFromId($transition$.params().id).then(function (
                response
              ) {
                post = response.data;
                return AppStates.configPost(post, "publish", "hard");
              });
            }
          },
        ],
      },
      params: {
        post: null,
        capability: "scheda_benefattore",
        act: {
          squash: true,
        },
        referer: {
          squash: true,
        },
      },
    },
    PENDING: {
      resolve: {
        id: [
          "$transition$",
          function ($transition$) {
            //console.log(arguments)
            return $transition$.params().id;
          },
        ],
        post: [
          "$transition$",
          function ($transition$) {
            //console.log($transition$.params().permalink)
            var post = $transition$.params().post;

            if (post) {
              return AppStates.configPost(post, "pending", "light");
            } else {
              return Api.getPostFromId($transition$.params().id).then(function (
                response
              ) {
                post = response.data;
                return AppStates.configPost(post, "pending", "hard");
              });
            }
          },
        ],
      },
      params: {
        post: null,
        capability: "scheda_benefattore",
        referer: {
          squash: true,
        },
      },
    },
    OPPORTUNITIES: {
      params: {
        //showFilters : false,
        //showAffinity : false,
        //showSettings : false,
        //columns : ['status','body','type','expire','icon'],
        viewMode: "modal",
        serpType: "opportunita",
        capability: "serp_opportunita",
        page: {
          type: "int",
          value: 1,
          squash: true,
        },
        q: {
          type: "json",
          value: {},
          squash: true,
          groupBy: "_source.groupBy",
        },
      },
    },
    DETAIL: {
      resolve: {
        /* slug: ['$transition$', function ($transition$) {
                            //console.log(arguments)
                            return $transition$.params().slug;
                        }],*/
        id: [
          "$transition$",
          function ($transition$) {
            //console.log(arguments)
            return $transition$.params().id;
          },
        ],
        searchId: [
          "$transition$",
          function ($transition$) {
            return $transition$.params().searchId;
          },
        ],
        post: [
          "$transition$",
          function ($transition$) {
            //console.log($transition$.params().permalink)
            var post = $transition$.params().post;

            if (post) {
              return AppStates.configPost(post, "publish", "light");
            } else {
              return Api.getPostFromId($transition$.params().id).then(function (
                response
              ) {
                post = response.data;
                return AppStates.configPost(post, "publish", "hard");
              });
            }
          },
        ],
      },
      params: {
        tab: {
          squash: true,
        },
        viewMode: "modal",
        act: {
          squash: true,
        },
        referer: {
          squash: true,
        },
        searchId: {
          squash: true,
        },
      },
    },
  };

  static configPost(post, state, mode) {
    switch (mode) {
      case "light":
        //post.permalink += post.permalink.indexOf('?') === -1 ? '?' : '&';
        break;
      case "hard":
        //console.log(post)
        post.permalink =
          process.env.WP_URL +
          "/?post_type=" +
          post.post_type +
          "&p=" +
          post.ID +
          "&";
        break;
    }

    switch (state) {
      case "pending":
        post.permalink = Config.options.pending_page;
        break;
      case "publish":
        post.permalink = post.permalink;
        break;
    }

    post.permalink += post.permalink.indexOf("?") === -1 ? "?" : "&";
    post.permalink += "headless=true&mode=" + mode + "&post_id=" + post.ID;

    post.userMeta = UserMeta.getUserMetaForPost(post.ID);
    post.userMetaPermission = UserMeta.getPermissionForUserMeta(post.ID);

    return post;
  }

  static getCommonParams(index, extensions) {
    var params = _.cloneDeep(AppStates.commons[index].params);
    return _.merge(params, extensions);
  }

  static getCommonResolve(index, extensions) {
    var resolve = _.cloneDeep(AppStates.commons[index].resolve);
    return _.merge(resolve, extensions);
  }

  #states = (() => {
    if ("advisor" === PacksManager.getInstance().pack.path) {
      return AdvisorStates;
    } else {
      return CustomerStates;
    }
  })();
  // TODO aggiungere gli altri stati
  #sections = {
    dashboard: ["explore"],
    liked: [
      "liked",
      "likedOpportunity",
      "likedOpportunity.detail",
      "likedDonors",
      "likedDonors.detail",
    ],
    opportunities: [
      "opportunitiesSettings",
      "opportunities.opportunity",
      "opportunities.pending",
      "opportunities",
      "opportunitiesDashboard",
      "opportunitiesGlossary",
    ],
    donors: [
      "donors",
      "donorsSearch",
      "donorsFavourites",
      "donorsFavourites.detail",
      "donorsFavourites.pending",
      "donorsArchived.detail",
      "donorsViewed",
      "donorsViewed.detail",
      "donorsArchived",
      "donorsSuggested",
      "donorsSuggested.detail",
      "donorsUnviewed",
      "donorsUnviewed.detail",
      "donorsSuggested.pending",
      "donorsSettings",
      "donorsDashboard",
      "donorsDirectContact",
      "donorsGlossary",
      "donorsSearch.detail",
      "donorsSerp",
      "donorsSerp.detail",
      "donorsSerp.pending",
    ],
    contents: [
      "contents",
      "contentSearch",
      "news",
      "news.article",
      "dataDriven",
      "dataDriven.article",
      "successfulCases",
      "successfulCases.article",
      "tools",
      "tools.article",
    ],
    community: [
      "community",
      "partnership",
      "advisors",
      "interview",
      "interview.article",
    ],
    search: ["search"],
    support: [
      "faq",
      "support",
      "supportDashboard",
      "howtoDonors",
      "howtoDonors.article",
      "howtoFilantropia",
      "howtoFilantropia.article",
      "howtoProfile",
      "howtoProfile.article",
      "howtoOpportunities",
      "howtoOpportunities.article",
      "howtoSearch",
      "howtoSearch.article",
      "howto",
      "howto.article",
      "upgrade",
      "upgradeLanding",
      "upgradeLandingDonors",
      "upgradeLandingContents",
      "notes",
      "feedback",
    ],
    settings: [
      "preferenze",
      "unauthorized",
      "renew",
      "preferences",
      "login",
      "profiling",
    ],
    upgrade: ["upgrade"],
    annotations: ["annotations", "annotations.detail"],
  };
  #hasSidebar = [
    "opportunities",
    "donors",
    "community",
    "liked",
    "support",
    "annotations",
  ];

  constructor() {
    if (!AppStates._instance) {
      AppStates._instance = this;
    }

    //console.log(Config._instance)
    return AppStates._instance;
  }

  static getInstance() {
    return this._instance;
  }

  static getSectionForState(state) {
    let res = false;
    _.forEach(
      AppStates.getInstance().#sections,
      (sectionStates, sectionKey) => {
        _.forEach(sectionStates, function (stateInSection) {
          if (stateInSection === state) {
            res = sectionKey;
          }
        });
      }
    );
    return res;
  }

  static createStateForCustomSerp(serp, name, slug, recordType) {
    const parentName =
      recordType === "opportunities"
        ? recordType
        : `donors${Utilities.slugToCamelCase(slug)}`;
    const detailName =
      recordType === "opportunities"
        ? "opportunities.opportunity"
        : `donors${Utilities.slugToCamelCase(slug)}.detail`;

    //console.log(parentName,detailName,name)

    CustomSerpDecorations.createDecoration(name, {
      title: `Ricerca personalizzata di ${
        recordType === "opportunities" ? "Bandi e opportunità" : "Donatori"
      }`,
      phrase: serp.label,
      noresults: {
        template: "opportunities/latest",
      },
      columns: recordType === "opportunities" ? ["affinity", "body", "type", "expire", "icon"] : [
      "affinity",
      "logo",
      "body",
      "territories",
      "type",
      "icon"]
    ,
      q: {
        orderBy: "latest",
        groupBy: "_source.groupBy",
        ...serp.options,
      },
      viewStyle: "table",
      showFilters: true,
      isDynamic: false,
    });

    return {
      parent: {
        name: parentName,
        component: recordType === "opportunities" ? recordType : "donorsList",
        url:
          recordType === "opportunities"
            ? `/opportunita/${name}/`
            : `/donors/${name}/`,
        resolve: this.getCommonResolve(recordType.toUpperCase()),
        params: {
          ...this.getCommonParams(recordType.toUpperCase(), {
            d: name,
          }),
        },
        data: {
          hasSidebar: true,
          section: recordType,
        },
      },
      detail: {
        name: detailName,
        component: "singleRecordComponent",
        url: "{id}/?act=&tab=&referer=",
        resolve: this.getCommonResolve("DETAIL"),
      },
    };
  }

  init() {
    // console.log(this.#states)
    // aggiungo gli stati al routing
    _.forEach(this.#states, function (state, key) {
      if (state.commonParams) {
        state.params = AppStates.getCommonParams(
          state.commonParams.index,
          state.commonParams.extensions
        );
      }
      if (state.commonResolve) {
        state.resolve = AppStates.getCommonResolve(
          state.commonResolve.index,
          state.commonResolve.extensions
        );
      }

      if (!state.data) state.data = {};
      state.data.section = "unknown";

      _.forEach(
        AppStates.getInstance().#sections,
        function (sectionStates, section) {
          _.forEach(sectionStates, function (sectionState) {
            if (sectionState === state.name) {
              state.data.section = section;
              state.data.hasSidebar =
                AppStates.getInstance().#hasSidebar.indexOf(section) > -1;
            }
          });
        }
      );

      if (state.data.section === "unknown")
        console.warn("nessuno stato per:" + state.name);

      //console.log(state)
    });

    return this.#states;
  }
}

export default AppStates;
