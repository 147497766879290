import Config from "./modules/Config";
import OnBoarding from "./modules/Dictionary/OnBoarding";
import UserMeta from "./modules/UserMeta";
import SurveyNPS from "./modules/SurveyNPS";
import SurveyPMF from "./modules/SurveyPMF";
import SurveyPTR from "./modules/SurveyPTR";
import Cdp from "./modules/Cdp";
import BeamerConnector from "./modules/Connectors/BeamerConnector";
import {loadProgressBar} from "./modules/loadProgressBar"

import './bootstrap.scss'
import './packages/_core/core'

import PackManager from "./modules/Account/PacksManager";
import PacksManager from "./modules/Account/PacksManager";


( function () {

    'use strict';


    loadProgressBar();

    const preloader = document.getElementById('smara-private__preload');


    $(function(){
        var root = document.documentElement;

        $('body').append('<div id="size-detector-container" class="container"></div>');
        root.style.setProperty('--container-width', $('#size-detector-container').width() + 'px');


        window.addEventListener("resize", function() {
            root.style.setProperty('--container-width', $('#size-detector-container').width() + 'px');
        });
    })
    /**
     * Init sentry
     * @returns {{exception}|*}
     */
    function traceErrors(){
        if(!parseInt(process.env.USE_SENTRY)) return;
        if(!window.Sentry){
            console.warn('Sentry not found');
            return;
        }
        if((`${process.env.GRANTER_ENV}`) === "production"){
            window.Sentry.init({
                dsn: "https://ee5a60f3b3ec4921bb213e4040727166@o245081.ingest.sentry.io/6230046",
                integrations: [new window.Sentry.Integrations.Angular({console: false })],
                environment: "production",
            });
        }else{
            window.Sentry.init({
                dsn: "https://ee5a60f3b3ec4921bb213e4040727166@o245081.ingest.sentry.io/6230046",
                integrations: [new window.Sentry.Integrations.Angular({console: false })],
                environment: "development",
                beforeSend(event, hint) {
                    // Check if it is an exception, and if so, show the report dialog
                    if (event.exception) {
                        window.Sentry.showReportDialog({ eventId: event.event_id });
                    }
                    return event;
                },
                release: false,
                debug: true
            });
        }
    }
    function trackingCookie() {


        /**
         * Facebook Pixel
         */
        (function () {
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '468198244245589');
            fbq('track', 'PageView');
        })();

        /**
         * Hotjar
         */

        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2202142,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');


    }

    traceErrors();

    if(process.env.GRANTER_ENV === "production") trackingCookie();


    const device = require("current-device").default;
    const screenWall = document.getElementById('smara-private__screen-wall');





    let config = new Config();



    config.init().then(async() => {

        // lo usermeta dev'essere inizializzato manualmente dopo che sono sicuro che il config remoto è stato popolato
        const userMeta = new UserMeta();
        userMeta.init();

        //Init onboarding
        new OnBoarding();


        preloader.remove(); // Removes the div with the 'div-02' id
        const surveyNPS = new SurveyNPS();
        const surveyPMF = new SurveyPMF();
        const surveyPTR = new SurveyPTR();

/*        const canny = new CannyConnector();
        canny.identify();
        canny.initChangeLog()*/
        //new HeadwayConnector();

        new BeamerConnector()


        const cdp = new Cdp();
        cdp.identifySmaraUser();
        // avvio dell'app manuale

        let pack = PackManager.availablePacks[userMeta.account_type.toUpperCase()]


        // console.log("ok ora faccio il bootstrap dell'app");
        const membershipManager = new PackManager(pack);
        //membershipManager.initWithPack();

        function startAngularApp(){
            angular.element(function () {
                screenWall.style.visibility = 'visible'
                angular.bootstrap(document, ['smara']);
            });
        }
        // Note that because a network request is involved, some indication
        // of loading would need to be shown in a production-level site/app.

        import(`./packages/${PacksManager.getInstance().pack.path}/main.js`).then(module => {

            startAngularApp();

        });


        return;


    }).catch(error => {
        //window.location = (`${process.env.LOGOUT_URL}`);
        //alert("impossibile connettersi al server.")
        let redirect_url;
        try {
            redirect_url = error.response.data.data.redirect_url;
            window.location = redirect_url;
        } catch (e){
            console.log(error);
        }
    })

})();


