class Utilities {
    constructor() {
        if (!Utilities._instance) {
            this.options = {};
            Utilities._instance = this;
        }

        return Utilities._instance;
    }

    static getInstance() {
        return this._instance;
    }

    static slugify(input) {

        if (!input)
            return;

        // make lower case and trim
        var slug = input.toLowerCase().trim();

        // replace invalid chars with spaces
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ');

        // replace multiple spaces or hyphens with a single hyphen
        slug = slug.replace(/[\s-]+/g, '-');

        return slug;

    }

    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    static uncapitalize(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }
    static slugToCamelCase(string,type = 'upper', separators = '-_.') {

        let result = string.replace(new RegExp('[' + separators + '][a-z]', 'ig'), function (s) {
            return s.substr(1, 1).toUpperCase();
        });

        if (type === 'upper') {
            result = Utilities.capitalize(result);
        } else {
            result = Utilities.uncapitalize(result);
        }
        return result;
    }

    static getPropertyByStringPath(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }


    // per la formattazione delle stringhe
    // "{0} is dead, but {1} is alive! {0} {2}".format("ASP", "ASP.NET")

    static formatString(format) {
        var args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    }


    static isSmallDevice() {
        return jQuery('#small-screen-detector').css('display') === 'none';
    }

    static isMediumDevice() {
        return jQuery('#medium-screen-detector').css('display') === 'none';
    }

    static canUseWebP() {
        var elem = document.createElement('canvas');

        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        }

        // very old browser like IE 8, canvas not supported
        return false;
    }

    static debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    /*static deboucedPromise(inner, ms) {
        var timer = null;
        var resolves = [];

        return function () {
            // Run the function after a certain amount of time
            clearTimeout(timer);
            timer = setTimeout(function () {
                // Get the result of the inner function, then apply it to the resolve function of
                // each promise that has been created since the last time the inner function was run
                var result = inner();
                resolves.forEach(function (r) {
                    r(result)
                });
                resolves = [];
            }, ms);

            return new Promise(function (r) {
                resolves.push(r)
            });
        };
    }*/

    static deboucedPromise(f, interval) {
        let timer = null;

        return (...args) => {
            clearTimeout(timer);
            return new Promise((resolve) => {
                timer = setTimeout(
                    () => resolve(f(...args)),
                    interval,
                );
            });
        };
    }

    static formatNumbers(number, decPlaces, decSep, thouSep) {
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
            decSep = typeof decSep === "undefined" ? "." : decSep;
        thouSep = typeof thouSep === "undefined" ? "," : thouSep;
        const sign = number < 0 ? "-" : "";
        const i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        let j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    }

    static sendEventToGA(attrs) {
        var options = options || {};
        if (window.location.hostname === 'italianonprofit.it') {
            window.ga('send', 'event', attrs['gaCategory'], attrs['gaAction'], attrs['gaLabel'], options);
        } else {
            console.log('ga per ->' + attrs['gaCategory'] + " " + attrs['gaAction'] + " " + attrs['gaLabel'] + " ");
        }

        this.setHotjarTag(attrs['gaAction']);

    }

    static setHotjarTag(tag) {
        window.hj = window.hj || function () {
            (hj.q = hj.q || []).push(arguments)
        };

        if (window.location.hostname === 'italianonprofit.it') {
            hj('tagRecording', [tag]);
        } else {
            console.log('hotjar tag per ->' + tag);
        }


    }
}

export default Utilities