
class CustomSerpDecorations {

    static customSerpDecoration = {};

    static createDecoration(slug,decoration) {
        //console.log(slug)
        CustomSerpDecorations.customSerpDecoration[slug] = decoration
    }

}

export default CustomSerpDecorations