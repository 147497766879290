import UserMeta from "./../UserMeta";
import Config from "./../Config";
import AppStates from "./../AppStates";

const _ = require("lodash")

class SegmentConnector {

    #eventsStateProperties = {}

    constructor() {

        if (!SegmentConnector._instance) {
            SegmentConnector._instance = this;

            let segmentKey = process.env.SEGMENT_KEY

            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="Enne7MwUSYeIk7IROqauS1xv9F7MH1lU";;analytics.SNIPPET_VERSION="4.15.3";
                analytics.load(segmentKey);
                analytics.page();
            }}();


            // coda su tutti gli eventi
            this.#eventsStateProperties = {
                "userId": UserMeta.userMeta.uid,
                "trial": UserMeta.isTrial,
                "account_type": UserMeta.userMeta.account_type,
                "plan": UserMeta.getInstance().userProduct.product.name.toUpperCase(),

            }
            // console.log(UserMeta.getInstance().userMemberships)
            // console.log()
        } else {
            console.log("class already inizialized")
        }
        //console.log(Config._instance)
        return SegmentConnector._instance;

    }

    mergeWithStatic(props) {
        if (props.id) {
            props.objectId = props.id;
            delete props.id;
        }
        return _.merge(_.cloneDeep(this.#eventsStateProperties), props);
    }

    identifySmaraUser() {
        const trait = {
            firstName: UserMeta.userMeta.first_name,
            lastName: UserMeta.userMeta.last_name,
            company: Config.options.userMeta.smara_nome_onp,
            website: Config.options.userMeta.smara_website_onp,
            team: Config.options.userMeta.smara_team_name,
            city: Config.options.userMeta.smara_legal_address.city,
            "email": UserMeta.userMeta.email
        }

        if (Config.options.renewalInfo.next_payment_date)
            trait._schedule_next_payment = Config.options.renewalInfo.next_payment_date

        SegmentConnector.identifyWithTrait(trait)

    }

    static getInstance() {
        return this._instance;
    }

    static identifyWithTrait(trait, apiOptions = null) {
        // disattivo il tracking se l'utente è un admin in modalità switch-to
        if (Config.options.ou) {
            console.log('Sei in switch-to, non mando nulla!');
            return;
        }

        return new Promise((resolve, reject) => {
            //console.log(SegmentConnector.getInstance().#eventsStateProperties.userId, SegmentConnector.getInstance().mergeWithStatic(trait))
            analytics.identify(SegmentConnector.getInstance().#eventsStateProperties.userId, SegmentConnector.getInstance().mergeWithStatic(trait), apiOptions,
                (response) => {
                    resolve(response)
                });
        })
    }

    static reset() {
        analytics.reset()
    }


    static trackPage(state, properties, apiOptions = null, callback = null) {
        // disattivo il tracking se l'utente è un admin in modalità switch-to
        if (Config.options.ou) {
            console.log('Sei in switch-to, non mando nulla!',state,properties);
            return;
        }

        let category, name = state;

        category = AppStates.getSectionForState(state);
        return new Promise((resolve, reject) => {
            analytics.page(category, name, SegmentConnector.getInstance().mergeWithStatic(properties), apiOptions, (response) => {
                resolve(response)
            });
        })
    }

    static trackEvent(eventName, properties,apiOptions = null) {
        //const userId = UserMeta.userMeta.uid
        // disattivo il tracking se l'utente è un admin in modalità switch-to
        if (Config.options.ou) {
            console.log('Sei in switch-to, non mando nulla!',eventName,properties);
            return;
        }

        return new Promise((resolve, reject) => {
            analytics.track(eventName, SegmentConnector.getInstance().mergeWithStatic(properties), apiOptions,
                (response) => {
                    resolve(response)
                });
        })


    }

}

export default SegmentConnector