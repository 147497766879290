const _ = require('lodash');

class PartnershipFlags {

    static flags = {
        "donors": [],
        "opportunities": [
            {
                'slug': 'not_expressed',
                'value': 'Nessuna preferenza...'
            },
            {
                'slug': 'not_interested',
                'value': 'Non mi interessa'
            },
            {
                'slug': 'partner',
                'value': 'Partner di progetto'
            },
            {
                'slug': 'capofila',
                'value': 'Ente Capofila'
            },
        ]
    }


    static valueMap = (function () {
        var result = {};
        _.forEach(PartnershipFlags.flags, function (type, key) {
            result[key] = {}
            _.forEach(type, function (value) {
                result[key][value.value] = {value: value.slug, label: value.value};
            });
        });
        return result
    })();
    static slugMap = (function () {
        var result = {};
        _.forEach(PartnershipFlags.flags, function (type, key) {
            result[key] = {}
            _.forEach(type, function (value) {
                result[key][value.slug] = {value: value.slug, label: value.value};
            })
        });
        return result
    })();
    static getFlagsForType = function (type) {
        if (PartnershipFlags.flags[type]) return PartnershipFlags.flags[type];
        return type === 'opportunita' ? PartnershipFlags.flags['opportunities'] : PartnershipFlags.flags['donors'];
    };
    static getValueMapForType = function (type) {
        if (PartnershipFlags.valueMap[type]) return PartnershipFlags.valueMap[type];
        return type === 'opportunita' ? PartnershipFlags.valueMap['opportunities'] : PartnershipFlags.valueMap['donors'];
    };
    static getSlugMapForType = function (type) {
        if (PartnershipFlags.valueMap[type]) return PartnershipFlags.slugMap[type];
        return type === 'opportunita' ? PartnershipFlags.slugMap['opportunities'] : PartnershipFlags.slugMap['donors'];
    }
}

export default PartnershipFlags