import UserMeta from "./UserMeta";
import Config from "./Config";
import Deus from "./Deus";
import Api from "./Api";
import Cdp from "./Cdp";
import moment from "moment";
import HubSpotConnector from "./HubSpotConnector";

const _ = require("lodash")

class SurveyPTR {

    static end_date = '2023-11-31';
    static start_date = '2023-04-01';
    
    constructor() {
        if (!SurveyPTR._instance) {
            SurveyPTR._instance = this;
        }
        this.init();
        //console.log(Config._instance)
        return SurveyPTR._instance;
    }

    dismiss(){
        let nextDate;
        nextDate = moment(new Date()).add(1, 'hour');
        localStorage.setItem(process.env.PTR_LOCAL_KEY, nextDate.toJSON())
    }
    
    /**
     * Determines if the current state is questionable.
     *
     * @returns {boolean} Returns true if the state is questionable, otherwise false.
     */
    isQuestionable() {

        return false;
        /*
        let isQuestionable = false;

        // può rispondere?
        if(UserMeta.getInstance().userProduct && UserMeta.getInstance().userProduct.subscription){
            const startSubscription = new Date(UserMeta.getInstance().userProduct.subscription._schedule_start);
            if (startSubscription > new Date(SurveyPTR.start_date) && startSubscription < new Date(SurveyPTR.end_date)) isQuestionable = true;
        }
        if (!isQuestionable) return false;

        // Ha già risposto?
        const lastPTR = UserMeta.getInstance().userPTR;
        if (lastPTR) return false;

        // ha dismesso la modale?
        const deniedPTR = localStorage.getItem(process.env.PTR_LOCAL_KEY);
        if (deniedPTR) {
            const today = moment();
            if (moment(deniedPTR).isBefore(today)) {
                localStorage.removeItem(process.env.PTR_LOCAL_KEY)
                isQuestionable = true;
            }
        }
        */
        return isQuestionable;
    }

    updateInfo(vote) {
        return Api.postV2({
            action: "set_ptr_vote",
            uid: UserMeta.userMeta.uid,
            vote: vote
        }, true).then(() => {
            Cdp.trackEvent("PTR Score", {value: vote}).then(() => {

            })
        });
    }


    init() {
        if (this.isQuestionable()) {
            setTimeout(() => {
                Cdp.trackEvent("PTR showed", {})
                Deus.$emit('openModal', {"key": "survey:PTR"});
            }, 1000)
        }
    }


    static getInstance() {
        return this._instance;
    }


}

export default SurveyPTR