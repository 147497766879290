class Toast {
    constructor() {
        if (!Toast._instance) {
            this.options = {};
            Toast._instance = this;
        }

        return Toast._instance;
    }

    static getInstance() {
        return this._instance;
    }

    static getBody(recordType, key) {

        if (Toast.dictionary[recordType])
            return Toast.dictionary[recordType][key];
        return false;

    }

    static dictionary = {
        "donors": {
            "like.add": "Donatore aggiunto alla tua lista",
            "like.remove": "Donatore rimosso dalla tua lista",
            "archive.add": "Donatore archiviato",
            "archive.remove": "Donatore rispristinato",
            "remove-notes": "Nota cancellata",
            "follow.add": "Segui questo donatore",
            "follow.remove": "Hai smesso di segnuire questo donatore"
        },
        "opportunities": {
            "like.add": "Stai seguendo questa Opportunità",
            "like.remove": "Hai smesso di seguire questa Opportunità",
            "archive.add": "Opportunità archiviata",
            "archive.remove": "Opportunità rispristinata",
            "remove-notes": "Nota cancellata",
            "follow.add": "Stai seguendo questa Opportunità",
            "follow.remove": "Hai smesso di seguire questa Opportunità"
        },
        "profile": {
            "profile.save": "Profilo aggiornato"
        },
        "permission": {
            "like.add": "Non puoi aggiungere preferiti",
            "like.remove": "Non hai il permesso di cancellare preferiti aggiunti da altri utenti del tuo team",
            "archive.add": "Non hai il permesso archiviare opportunità o donatori con il tuo ruolo",
            "archive.remove": "Non hai il permesso di ripristinare opportunità o donatori con il tuo ruolo",
            "remove-notes": "Non hai il permesso di cancellare note inserite da altri utenti del tuo team",
            "follow.add": "Non hai il permesso di fare questa operazione",
            "follow.remove": "Non hai il permesso di fare questa operazione"
        }
    }
}

export default Toast