class Avatar {
    constructor() {
        if (!Avatar._instance) {
            this.options = {};
            Avatar._instance = this;
        }

        return Avatar._instance;
    }

    static getInstance(){
        return this._instance;
    }

    static dictionary = {
        'alpha': {
            name: 'Alpha - 4Culture',
            avatar: '',
            features: 'APS che opera a livello nazionale e che tratta i temi legati alla cultura.',
            body: '#Patrimonio culturale #Arti performative #Creatività',
            slug: 'alpha',
            id: 1
        },
        'beta': {
            name: 'Beta - UCare',
            avatar: '',
            features: 'Fondazione che opera a livello nazionale che si occupa di Salute.',
            body: '#Malati #Assistenza sanitaria #Ricerca',
            slug: 'beta',
            id: 2
        },
        'gamma': {
            name: 'Gamma - BeWorld',
            avatar: '',
            features: 'ONG che opera a livello internazionale che tratta diverse tematiche.',
            body: '#adozioni a distanza #Povertà #Sviluppo',
            slug: 'gamma',
            id: 3
        }
    }
}

export default Avatar