import UserMeta from "./UserMeta";
import Config from "./Config";
import Deus from "./Deus";
import Api from "./Api";
import Cdp from "./Cdp";
import moment from "moment";
import HubSpotConnector from "./HubSpotConnector";

const _ = require("lodash")

class SurveyPMF {

    constructor() {
        if (!SurveyPMF._instance) {
            SurveyPMF._instance = this;
        }
        this.init();
        return SurveyPMF._instance;
    }

    dismiss(forever){
        let nextDate;
        if(forever){
            nextDate = moment(new Date()).add(1, 'year');
        }else{
            nextDate = moment(new Date()).add(1, 'hour');
        }
        localStorage.setItem(process.env.PMF_LOCAL_KEY, nextDate.toJSON())
    }
    /**
     * Determines if the current state is questionable.
     *
     * @returns {boolean} Returns true if the state is questionable, otherwise false.
     */
    isQuestionable() {

        return false;
        
        const lastPMF = UserMeta.getInstance().userPMF;
        const hasPMF = Object.keys(lastPMF).length === 2;
        if(hasPMF) return false;

        // not for trial
        const isTrial = UserMeta.isTrial;
        if(isTrial) return false;

        // check se su local storage ha dismesso la modale e se si da quanto tempo
        const deniedPMF = localStorage.getItem(process.env.PMF_LOCAL_KEY);
        if (deniedPMF) {
            const today = moment();
            if (moment(deniedPMF).isBefore(today)) {
                localStorage.removeItem(process.env.NPS_LOCAL_KEY)
                return true;
            }
        }

        return !deniedPMF;



    }

    updateInfo(key,value) {
        return Api.postV2({
            action: "set_pmf_vote",
            uid: UserMeta.userMeta.uid,
            key: key,
            value: value
        }, true).then(() => {
            Cdp.trackEvent("PMF Score", {value: value, pmf_key: key}).then(() => {

            })
        });
    }

    sendFeedback(msg) {
        Cdp.trackEvent("PMF Feedback", {msg: msg.body,pmf_nots:msg.nots,pmf_iid:msg.iid}).then(() => {
            HubSpotConnector.sendForm('PmfSurveyFeedback', {
                "submittedAt": new Date().getTime(),
                "fields": [
                    {
                        name: "email",
                        value: UserMeta.userMeta.email
                    },
                    {
                        name: "TICKET.hs_ticket_category",
                        value: "PRODUCT_ISSUE"
                    },
                    {
                        name: "TICKET.subject",
                        value: "PMF Feedback"
                    },
                    {
                        name: "TICKET.content",
                        value: msg.body ?? '-'
                    },
                    {
                        name: "pmf_nots",
                        value: msg.nots
                    },
                    {
                        name: "pmf_iid",
                        value: msg.iid
                    }
                ],
                "context": {
                    //"hutk": document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                    "pageUri": window.location.href,
                    "pageName": document.title
                },
            })
        })
    }


    init() {
        if (this.isQuestionable()) {
            setTimeout(() => {
                Cdp.trackEvent("PMF showed", {})
                Deus.$emit('openModal', {"key": "survey:PMF"});
            }, 1000)
        }
    }


    static getInstance() {
        return this._instance;
    }


}

export default SurveyPMF