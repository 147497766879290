import axios from "axios";

class HubSpotConnector {

    static defaultParams = {
        portalId: "8895135",
    };

    static forms = {
        url: 'https://api.hsforms.com/submissions/v3/integration/submit/',
        service: {
            formId: "e27f134e-3fd1-4697-bf5a-97592d52a60a"
        },
        waitingList: {
            formId: "65beaad3-6a90-4088-9ad1-5ef53754b86f"
        },
        NpsSurveyScore: {
            formId: "75f51462-b28d-444a-9430-be712c083597"
        },
        NpsSurveyFeedback: {
            formId: "0ca7d59c-12d6-4b14-b08a-2051a3b72463"
        },
        PmfSurveyFeedback: {
            formId: "9e1369ea-7d55-4fc2-9634-6b2a8dbb786b"
        }
    }

    static sendForm = function (form, data) {
        return axios.post(
            HubSpotConnector.forms.url + HubSpotConnector.defaultParams.portalId + '/' + HubSpotConnector.forms[form].formId,
            JSON.stringify(data),
            {

                cache: false,
                headers: {'Content-Type': 'application/json'}

            }
        )
    }
}

export default HubSpotConnector;

