const _ = require('lodash');
class ProspectFlags {
    constructor() {
        if (!ProspectFlags._instance) {
            this.options = {};
            ProspectFlags._instance = this;
        }

        return ProspectFlags._instance;
    }

    static getInstance(){
        return this._instance;
    }

    static dictionary = {
        "donors" : [
            {
                'slug' : 'da-contattare',
                'value': 'Da Contattare'
            },
            {
                'slug' : 'contattato',
                'value': 'Contattato'
            },
            {
                'slug' : 'appuntamento-fissato',
                'value': 'Appuntamento Fissato'
            },
            {
                'slug' : 'attesa-di-feedback',
                'value': 'Attesa di feedback'
            },
            {
                'slug' : 'proposta-inviata',
                'value': 'Proposta inviata'
            },
            {
                'slug' : 'proposta-in-firma',
                'value': 'Proposta in Firma'
            },
            {
                'slug' : 'proposta-firmata',
                'value': 'Proposta Firmata'
            },
            {
                'slug' : 'proposta-rifiutata',
                'value': 'Proposta Rifiutata'
            },

        ],
        "opportunities" : [
            {
                'slug' : 'da-analizzare',
                'value': 'Da analizzare'
            },
            {
                'slug' : 'in-fase-di-analisi',
                'value': 'In analisi'
            },
            {
                'slug' : 'in-fase-di-presentazione',
                'value': 'In presentazione'
            },
            {
                'slug' : 'proposta-inviata',
                'value': 'Inviata'
            },
            {
                'slug' : 'proposta-vinta',
                'value': 'Vinta'
            },
            {
                'slug' : 'proposta-persa',
                'value': 'Persa'
            },
            {
                'slug' : 'in-fase-di-rendicontazione',
                'value': 'In rendicontazione'
            },
            {
                'slug' : 'proposta-conclusa',
                'value': 'Conclusa'
            },
        ]
    }

    static valueMap = (function(){
        var result = {};
        _.forEach(ProspectFlags.dictionary, function (type, key) {
            result[key] = {}
            _.forEach(type, function (value) {
                result[key][value.value] = { value : value.slug};
            });
        });
        return result
    })();

    static slugMap = (function(){
        var result = {};
        _.forEach(ProspectFlags.dictionary, function (type, key) {
            result[key] = {}
            _.forEach(type, function (value) {
                result[key][value.slug] = {value: value.value};
            })
        });
        return result
    })();


    static getFlagsForType(type) {
        if(ProspectFlags.dictionary[type]) return ProspectFlags.dictionary[type];
        return type === 'opportunita' ? ProspectFlags.dictionary['opportunities'] : ProspectFlags.dictionary['donors'];
    }

    static getValueMapForType(type) {
        if(ProspectFlags.valueMap[type]) return ProspectFlags.valueMap[type];
        return type === 'opportunita' ? ProspectFlags.valueMap['opportunities'] : ProspectFlags.valueMap['donors'];
    }
}

export default ProspectFlags