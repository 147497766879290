//@ts-ignore
import rules from './membership_rules.json' assert {type: 'json'};
import {type MembershipType} from "../../types/membership.type";
import {
    type ACLFeature,
    type ACLMembershipRules,
    type ACLRules,
    type Features,
    type GranterCapabilities
} from "../../types/acl.type";

/**
 * GranterACL is a module that provides access control logic based on membership rules.
 */
const GranterACL = () => {

    /**
     * Retrieves the rules from the imported JSON file.
     *
     * @returns {object} The rules object.
     */
    const getRules = (): ACLRules => {
        return rules;
    }

    /**
     * Checks if a membership can access a specific feature.
     *
     * @param {MembershipType} membership - The type of membership.
     * @param {string} feature - The feature to check access for.
     * @returns {boolean} True if the membership can access the feature, otherwise false.
     * @throws {Error} If the membership rules are not found.
     */
    const canAccessToFeature = (membership: MembershipType, feature: Features): boolean => {
        // Prelevo le regole dell'abbonamento
        const {getMembershipRules} = GranterACL();
        const membershipRules = getMembershipRules(membership);

        // Verifico se lo stato non esiste
        if (!membershipRules.features[feature]) {
            return true;
        }

        // Verifico se l'abbonamento può accedere alla sezione
        return membershipRules.features[feature].access;
    }

    /**
     * Checks if there is a redirect for a capability and returns the state name to redirect the user to.
     *
     * @param {MembershipType} membership - The type of membership.
     * @param {string} capability - The capability to check for a redirect.
     * @returns {string|false} The state name to redirect to, or false if no redirect exists.
     * @throws {Error} If the membership rules are not found.
     */
    const getCapabilityRedirect = (membership: MembershipType, capability: GranterCapabilities): string | false => {
        // Prelevo le regole dell'abbonamento
        const {getMembershipRules} = GranterACL();
        const membershipRules = getMembershipRules(membership);

        // Verifico se non esiste la capability
        if (!membershipRules.capabilities[capability]) {
            return false;
        }

        // Verifico se esiste il redirect
        if (!membershipRules.capabilities[capability].redirectState) {
            return false;
        }
        return membershipRules.capabilities[capability].redirectState;
    }

    /**
     * Retrieves the membership rules for a given membership type.
     *
     * @param {MembershipType} membership - The type of membership.
     * @returns {ACLMembershipRules} The rules for the given membership.
     * @throws {Error} If the membership is not found.
     */
    const getMembershipRules = (membership: MembershipType): ACLMembershipRules => {
        // Prelevo le regole dell'abbonamento
        const membershipRules = rules[membership];
        // Se l'abbonamento non esiste
        if (!membershipRules) {
            throw new Error(`Membership ${membership} not found`);
        }
        return membershipRules;
    }

    /**
     * Retrieves the rules for a specific feature within a membership.
     *
     * @param {MembershipType} membership - The type of membership.
     * @param {string} feature - The feature to retrieve rules for.
     * @returns {ACLFeature} The rules for the specified feature.
     * @throws {Error} If the feature is not found.
     */
    const getFeatureRule = (membership: MembershipType, feature: Features): ACLFeature => {
        // Prelevo le regole dell'abbonamento
        const {getMembershipRules} = GranterACL();
        const membershipRules = getMembershipRules(membership);

        // Verifico se esiste la feature
        if (!membershipRules.features[feature]) {
            throw new Error('Feature not found');
        }

        return membershipRules.features[feature];
    }

    /**
     * Retrieves the menu state for a specific membership.
     * @param membership
     */
    const getMenuState = (membership: MembershipType):  {[key: string]: boolean}  => {
        // Prelevo le regole dell'abbonamento
        const {getMembershipRules} = GranterACL();
        const membershipRules = getMembershipRules(membership);
        return membershipRules.menu;
    }

    /**
     * Retrieves the decoration overrides for a specific membership.
     * @param membership
     */
    const getMembershipDecorationOverrides = (membership: MembershipType) => {
        // Prelevo le regole dell'abbonamento
        const {getMembershipRules} = GranterACL();
        const membershipRules = getMembershipRules(membership);
        if(!membershipRules.decorations) {
            return {};
        }else return membershipRules.decorations;
    }

    return {
        getMembershipDecorationOverrides,
        getMenuState,
        getFeatureRule,
        getMembershipRules,
        getCapabilityRedirect,
        canAccessToFeature,
        getRules
    }
}

export default GranterACL;