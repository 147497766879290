import granterACL from "../Acl/GranterACL";
import UserMeta from "../UserMeta";

const _ = require('lodash');
const {getCapabilityRedirect} = granterACL();

class OnBoarding {
    constructor() {
        if (!OnBoarding._instance) {
            this.options = {};
            OnBoarding._instance = this;
        }

        // se l'utente ha le corrispettive capabilities allora vengono aggiunti gli elementi della checklist
        _.forEach(OnBoarding.checklistCapabilities, function (value, key) {
            if(!getCapabilityRedirect(UserMeta.getInstance().userProduct.product.name,key)){
                OnBoarding.checklist = _.concat(OnBoarding.checklist,value);
            };
        });

        return OnBoarding._instance;
    }

    static getInstance() {
        return this._instance;
    }

    static checklistCapabilities = {
        "scheda_opportunita": [
            {
                "title": "Trova e segui la tua prima opportunità",
                "description": "<strong>Segui una opportunità che ti interessa per ricevere aggiornamenti dedicati.</strong><br>Ogni giorno vengono pubblicate nuove opportunità nella suite di granter. Puoi navigarle in ogni momento, a partire da quelle che hanno un buon livello di affinità con il tuo ente." +
                    "<div ><a href='/assistenza/tutorials/opportunita/17276/'>Guarda questo video per filtrare le opportunità consigliate</a></div>",
                "belongTo": 0,
                "state": "opportunities({ d : 'opportunities.suggested', q : {},  page : 1  })",
                "validationRules": ["opportunities.like"]
            },
            {
                "title": "Organizza la tua candidatura",
                "description": "Personalizza il tuo elenco di opportunità e scegli lo stato di analisi: in questo modo darai le giuste priorità al tuo lavoro." +
                    "<div ><a href='/assistenza/tutorials/opportunita/17276/'>Tutto quello che c’è da sapere lo trovi qui</a></div>",
                "belongTo": 1,
                "state": "opportunities({ d : 'opportunities.favourites', q : {} , page : 1 })",
                "validationRules": ["opportunities.flag", "opportunities.prioritize"]

            }],
        "scheda_benefattore": [
            {
                "title": "Cerca e aggiungi alla lista un nuovo donatore",
                "description": "L’attività di prospecting è una delle più impegnative in termini di tempo e raccolta informazioni: cosa interessa al donatore? chi ha finanziato in passato? come posso contattarlo? All’interno delle schede dei donatori trovi queste e altre informazioni. Cerca un donatore interessante e aggiungilo alla tua lista." +
                    "<div ><a href='/assistenza/tutorials/opportunita/17358/'> Qui alcuni tips per navigare il profilo dei donatori.</a></div>",
                "belongTo": 2,
                "state": "donorsSuggested",
                "validationRules": ["donors.like"]
            },
            {
                "title": "Crea la tua lista prospect",
                "description": "Aggiungi potenziali donatori e costruisci la tua lista personalizzata su granter, in questo modo oltre a ricevere aggiornamenti specifici sul singolo donatore (e non chiederti più se il responsabile CSR è sempre lo stesso), puoi definire le priorità e condividere facilmente con i colleghi lo stato di avanzamento delle tue attività. Prova subito a cambiare uno stato o ad assegnare una priorità. " +
                    "<div ><a href='/assistenza/tutorials/opportunita/17337/'>In questa breve guida puoi scoprire tutte le funzioni.</a></div>",
                "belongTo": 3,
                "state": "donorsFavourites",
                "validationRules": ["donors.flag", "donors.prioritize"]

            }
        ],
        "news": [
            {
                "title": "Stupisci i colleghi",
                "description": "Ma cosa accade fuori dal mondo della raccolta fondi? Cosa sta cambiando nel mercato filantropico o ancora dove puoi trovare dati e informazioni per essere sempre sul pezzo?!\n" +
                    "La risposta a questa domanda è facile: è tutto nella sezione di contenuti e approfondimenti su granter. Leggi un contenuto!",
                //"<div ><a href='/assistenza/tutorials/opportunita/17276/'>Guarda questo video per filtrare le opportunità consigliate</a></div>",
                "belongTo": 4,
                "state": "contents",
                "validationRules": ["content.view"]

            }
        ]
    };

    static checklist = [
        {
            "title": "Attiva il sistema di affinità",
            "description": "Trova il prossimo bando a cui partecipare",
            "belongTo": -1,

            "validity": true,
            "active": false,
            "validationRules": ["profiling.completed"]
        }
    ]
}

export default OnBoarding