import UserMeta from "./../UserMeta";
import Config from "./../Config";
import AppStates from "./../AppStates";


const _ = require("lodash")

class RudderStackConnector {

    #eventsStateProperties = {}

    constructor() {

        if (!RudderStackConnector._instance) {
            RudderStackConnector._instance = this;

            let rudderstackKey = process.env.RUDDERSTACK_KEY

            !function () {
                var e = window.rudderanalytics = window.rudderanalytics || [];
                e.methods = ["load", "page", "track", "identify", "alias", "group", "ready", "reset", "getAnonymousId", "setAnonymousId", "getUserId", "getUserTraits", "getGroupId", "getGroupTraits"], e.factory = function (t) {
                    return function () {
                        var r = Array.prototype.slice.call(arguments);
                        return r.unshift(t), e.push(r), e
                    }
                };
                for (var t = 0; t < e.methods.length; t++) {
                    var r = e.methods[t];
                    e[r] = e.factory(r)
                }
                e.loadJS = function (e, t) {
                    var r = document.createElement("script");
                    r.type = "text/javascript", r.async = !0, r.src = "https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";
                    var a = document.getElementsByTagName("script")[0];
                    a.parentNode.insertBefore(r, a)
                }, e.loadJS(),
                    e.load(rudderstackKey, "https://italianonptmin.dataplane.rudderstack.com"),
                    e.page()
            }();

            // coda su tutti gli eventi
            this.#eventsStateProperties = {
                "userId": UserMeta.userMeta.uid,
                "trial": UserMeta.isTrial,
                "account_type": UserMeta.userMeta.account_type,
                "plan": UserMeta.getInstance().userProduct.product.name.toUpperCase(),

            }
            // console.log(UserMeta.getInstance().userMemberships)
            // console.log()
        } else {
            console.log("class already inizialized")
        }

        //console.log(Config._instance)
        return RudderStackConnector._instance;

    }

    mergeWithStatic(props) {

        if (props.id) {
            props.objectId = props.id;
            delete props.id;
        }
        return _.merge(_.cloneDeep(this.#eventsStateProperties), props);
    }

    identifySmaraUser() {
        const trait = {
            firstName: UserMeta.userMeta.first_name,
            lastName: UserMeta.userMeta.last_name,
            company: Config.options.userMeta.smara_nome_onp,
            website: Config.options.userMeta.smara_website_onp,
            team: Config.options.userMeta.smara_team_name,
            city: Config.options.userMeta.smara_legal_address.city,
            "email": UserMeta.userMeta.email
        }

        if (Config.options.renewalInfo.next_payment_date)
            trait._schedule_next_payment = Config.options.renewalInfo.next_payment_date

        RudderStackConnector.identifyWithTrait(trait)

    }

    static getInstance() {
        return this._instance;
    }

    static identifyWithTrait(trait) {
        // disattivo il tracking se l'utente è un admin in modalità switch-to
        if (Config.options.ou) {
            console.log('Sei in switch-to, non mando nulla!');
            return;
        }

        return new Promise((resolve, reject) => {
            rudderanalytics.identify(RudderStackConnector.getInstance().#eventsStateProperties.userId, RudderStackConnector.getInstance().mergeWithStatic(trait),
                (response) => {
                    resolve(response)
                });
        })
    }

    static reset() {
        rudderanalytics.reset()
    }


    static trackPage(state, properties, apiOptions = null, callback = null) {
        // disattivo il tracking se l'utente è un admin in modalità switch-to
        if (Config.options.ou) {
            console.log('Sei in switch-to, non mando nulla!',state,properties);
            return;
        }

        let category, name = state;

        category = AppStates.getSectionForState(state);
        return new Promise((resolve, reject) => {
            rudderanalytics.page(category, name, RudderStackConnector.getInstance().mergeWithStatic(properties), apiOptions, (response) => {
                resolve(response)
            });
        })
    }

    static trackEvent(eventName, properties) {
        //const userId = UserMeta.userMeta.uid
        // disattivo il tracking se l'utente è un admin in modalità switch-to
        if (Config.options.ou) {
            console.log('Sei in switch-to, non mando nulla!',eventName,properties);
            return new Promise((resolve) => {
                return resolve();
            });
        }

        return new Promise((resolve, reject) => {
            rudderanalytics.track(eventName, RudderStackConnector.getInstance().mergeWithStatic(properties),
                (response) => {
                    resolve(response)
                });
        })


    }

}

export default RudderStackConnector