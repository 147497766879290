import Api from './Api';
import UserMeta from "./UserMeta";
import Deus from "./Deus";
import PacksManager from "./Account/PacksManager";

class Tour {
    constructor() {
        if (!Tour._instance) {
            this.options = {};
            Tour._instance = this;
        }

        return Tour._instance;
    }

    static getInstance() {
        return this._instance;
    }

    static services = {
        modals: {
            customer: {
                "opportunities": {
                    "template": "ng-templates/ui/modals/tour/opportunities.html",
                    "delay": 2000
                },
                "donorsSuggested.detail": {
                    "template": "ng-templates/ui/modals/tour/donors.html",
                    "delay": 1000
                },
                "donorsFavourites": {
                    "template": "ng-templates/ui/modals/tour/prospect.html",
                    "delay": 1000
                },
                "explore": {
                    "template": "ng-templates/ui/modals/tour/explore.html",
                    "delay": 1000
                }
            },
            trials: {

                "opportunities": {
                    "template": "ng-templates/ui/modals/tour/opportunities.html",
                    "delay": 2000
                },
                "donorsSuggested.detail": {
                    "template": "ng-templates/ui/modals/tour/donors.html",
                    "delay": 1000
                },
                "donorsFavourites": {
                    "template": "ng-templates/ui/modals/tour/prospect.html",
                    "delay": 1000
                },
            }
        },
        tooltips: {
            customer: {
                "opportunities": {
                    "target": "#serp-filters__add",
                    "options": {
                        "html": true,
                        "title": "Filtra il database e avvia la ricerca",
                        "placement": "right",
                        "customClass": "serp-filters__tooltipTour ",
                        ///"container": '#serp-filters__wrapper'
                    },

                    "delay": 1000
                }
            },
            trials: {
                "opportunities": {
                    "target": "#serp-filters__add",
                    "options": {
                        "html": true,
                        "title": "Filtra il database e avvia la ricerca",
                        "placement": "right",
                        "customClass": "serp-filters__tooltipTour ",
                        ///"container": '#serp-filters__wrapper'
                    },

                    "delay": 1000
                }
            }
        }
    }


    // ritorna in quali sezioni devo mostrare una modale
    static timers = [];


    static getTriggers() {

        //console.log(UserMeta.isTrial)

        let result = {};
        let activePack = PacksManager.getInstance().pack.path;

        _.forEach(Tour.services, (serviceSubscription, serviceKey) => {
            const service = UserMeta.isTrial ? Tour.services[serviceKey].trials : Tour.services[serviceKey][activePack];
            _.forEach(service, function (value, key) {

                service[key].type = serviceKey;
                service[key].state = key;

                if (_.isUndefined(UserMeta.getInstance().userMeta.smara_tour[serviceKey]) || _.isUndefined(UserMeta.getInstance().userMeta.smara_tour[serviceKey][key])) {
                    if (!result[key]) result[key] = [];
                    //console.log(key)
                    result[key].push(service[key]);
                }
            });
        })
        // console.log(userMeta)

        //console.log("tour attive da mostrare:", result)
        return result
    };

    static triggerService(serviceKey, serviceData) {
        console.log(serviceData,serviceKey);
        switch (serviceData.type) {
            case "modals" :
                Tour.timers.push(setTimeout(function () {
                    Deus.$emit('openModalTour', serviceData);
                    Tour.updateState(serviceKey, true, "modals").catch(e => {
                        console.log(e)
                    })
                }, serviceData.delay));
                break;
                /*
            case  "tooltips" :
                Tour.timers.push(setTimeout(function () {
                    Deus.$emit('openTooltipTour', serviceData);
                    Tour.updateState(serviceKey, true, "tooltips").catch(e => {
                        console.log(e)
                    })
                }, serviceData.delay));
                break;
                 */
        }
    };

    static updateState(key, state, service) {

        console.log('qui',UserMeta.getInstance().userMeta.smara_tour[service],state,service);
        if(_.isUndefined(UserMeta.getInstance().userMeta.smara_tour[service])) UserMeta.getInstance().userMeta.smara_tour[service] = {};
        UserMeta.getInstance().userMeta.smara_tour[service][key] = state;
        //console.log(key,state);

        console.log('state',UserMeta.getInstance().userMeta.smara_tour);

        //console.log(UserMeta.getInstance().userMeta.smara_tour.modals)


        /*  if (window.localStorage) {
              window.localStorage.setItem('_USERPROFILE_', JSON.stringify(userMeta));
          }*/

        return Api.post({
            action: 'set_user_metadata',
            profile: JSON.stringify(UserMeta.getInstance().userMeta)
        });

    }

    // questa funziona cancella tutti i trigger di modali e tooltip attivi, viene chiamata al cambio di stato
    static clearStateTimers() {
        _.forEach(Tour.timers, (i, k) => {
            clearTimeout(i);
        })
    }

    // questa funzione attiva le modali e i tooltip di stato, viene chiamata al cambio di stato
    static activateStateTimers() {

        // cancello l'apertura di qualsiasi modale
        Tour.clearStateTimers();

        // triggero modali del tour
        _.forEach(Tour.getTriggers(), function (trigger) {
            _.forEach(trigger, (value, key) => {
                if (value.state === Deus.state.name) {
                    // li metto dentro deus perchè gli stati possono triggerare in condizioni diverse.
                    // ad esempio una serp triggererà gli eventi solo al loading compiuto dei risultati
                    Deus.addTrigger(function () {
                        Tour.triggerService(value.state, value)
                    })
                }
            })
        });
    }
}

export default Tour