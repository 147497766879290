import Api from "./Api";

import Cdp from "./Cdp";

const axios = require('axios');

class Logger {
    static logs = {
        "like": {
            "add": 'add-favourite',
            "remove": 'remove-favourite'
        },
        "archive": {
            "add": 'add-archive',
            "remove": 'remove-archive'
        },

        "flag": {
            "add": 'add-flag',
            "remove": 'remove-flag'
        },

        "follow": {
            "add": 'add-follow',
            "remove": 'remove-follow'
        },

        "custom-serp" : {
            "add" : "add-custom-serp",
            "remove" : "remove-custom-serp"
        },

        "prioritize": {
            "add": 'add-priority',
            "remove": 'remove-priority'
        },
        "notes": {
            "remove": 'remove-note',
            "add": 'add-note'
        },
        "view": {
            "add": "view",
            "remove": "remove-view",
        },
        "search": {
           "donors": "search-donors",
           "opportunities": "search-opportunities",
        },
        "ux": {
            "outbound-link": "outbound-link"
        },
        "contact": {
            "donor": "request-donors",
            "opportunity": "request-opportunities",
            "content": "request-content",
            "info": "feedback-info",
            "appointment": "feedback-appointment",
            "suggest": "feedback-suggest",
            "request": "feedback-custom",
            "abuse": "feedback-abuse",
            "other": "feedback-abuse",
            "waiting-list": "waiting-list"
        },
        "preferences": {
            "change": "change-preferences"
        },
        "looking_for_partner": {
            "add": "set-partnership"
        },
        "partner": {
            "send": "send-email",
            "show": "show-partner-contacts",
            "copy": "copy-partner-email",
            "email": "email-partner",
            "website": "open-website-partner",
        },
        advisor : {
            "sendRequest" : "send-request"
        }
    }


    /**
     *
     * @param preference
     * @param action
     * @param info
     * @returns {*}
     */
    static logEvent(preference, action, info) {

        if (!this.logs[preference] || !this.logs[preference][action]) {

            console.error("mappatura log non trovata per: ", preference, action);

            return;
        }
        const log = {
            action: this.logs[preference][action],
            data: info
        }

        /*return Logger.getInstance().axiosInstance.post(Api.defaultParams.post.urlV2, {
            action: 'log_action',
            data: JSON.stringify(log)
        })*/

        if(preference !== "search"){
            Cdp.trackEvent(log.action,log.data)
        }
        return Api.postV2({
            action: 'log_action',
            data: JSON.stringify(log)
        },true)
    };

    /**
     *
     * @param from
     * @param to
     * @returns {*}
     */
    static logChangeState(from, to) {

        if(!from) return;
        var log = {
            action: 'change-state',
            data: {
                from: from,
                to: to,
                location: window.location.href
            }
        }
        //console.log(log,logs[preference][action],preference);




        //Cdp.trackEvent(log.action,log.data)
        Cdp.trackPage(to,log.data);



        return Api.postV2({
            action: 'log_action',
            data: JSON.stringify(log)

        },true)
    }
}

export default Logger;