import Api from "./Api"
import Logger from "./Logger";
import Deus from "./Deus"
import Utilities from "./Utilities";

class CustomSerp {
    static routes

    constructor() {
        if (!CustomSerp._instance) {
            this.options = {};
            CustomSerp._instance = this;
        }

        return CustomSerp._instance;
    }

    static getInstance() {
        return this._instance;
    }


    static async save(options, label, scope) {
        // Rimuovo la configurazione di algolia che contiene i filtri default
        // vanno rimossi perché sono dinamici e dipendono dalle preferenze dell'utente
        // o dalla data in cui si effettua la ricerca
        // TODO quando si implementerà l'indice dei donatori si dovrà aggiungere un controllo anche per quell'indice
        if(options[process.env.ALGOLIA_INDEX]){
            delete options[process.env.ALGOLIA_INDEX].configure;
        }

        const params = {
            label: label,
            scope: scope,
            options: JSON.stringify(options)
        }
        const id = await Api.postV2({
            action: "save_custom_serp",
            ...params
        })
        await Logger.logEvent('custom-serp', "add", params);
        await CustomSerp.init();
        return id;
    }


    static async delete(id) {
        const result = await Api.postV2({
            action: "delete_custom_serp",
            id: id
        })
        await Logger.logEvent('custom-serp', "remove", {});
        await CustomSerp.init();
        return result;
    }


    static async getAll() {
        const {data} = await Api.postV2({
            action: "get_custom_serp",
            //scope: scope
        })
        if(!data) return [];

        const list = data.map((item) => {
            //console.log(item)
            let obj;
            if(typeof item.meta_value === "string"){
                obj = JSON.parse(item.meta_value);
            }else obj = item.meta_value;
            if (obj.options && typeof obj.options === "string") {
                obj.options = JSON.parse(obj.options)
            }
            obj.id = item.umeta_id
            if(!obj.slug || typeof obj.slug === "undefined"){

                obj.slug = Utilities.slugify(obj.label)
            }
            return obj
        });

        /**
         * Filtra le serp che non hanno l'indice di algolia configurato
         * (tranne che per i donatori che utilizzano ancora la vecchia serp)
         */
        return list.filter(serp => {
            return (serp.scope === 'opportunities' && serp.options[process.env.ALGOLIA_INDEX] || serp.scope ==='donors')})

    }

    static async init() {
        const result = await this.getAll();
        CustomSerp.routes = {
            opportunities : result.filter(customSerp=>customSerp.scope === 'opportunities'),
            donors : result.filter(customSerp=>customSerp.scope === 'donors')
        }
        Deus.$emit("customSerpUpdate",CustomSerp.routes)
        return CustomSerp.routes
    }

    /**
     * Ritorna le serp salvate di uno specifico tipo
     * @param type opportunities | donors
     * @param forceReload boolean Ricarica i dati dal server
     * @return {Promise<object[]>}
     */
    static async getRoutes(type, forceReload = false) {
        if (!CustomSerp.routes || forceReload) {
            await CustomSerp.init();
        }
        // check if type exist
        if (!CustomSerp.routes[type]) {
            throw new Error(`CustomSerp type ${type} not found`)
        }
        return CustomSerp.routes[type]
    }
}

export default CustomSerp