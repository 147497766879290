

class RecordTypesMap {
    constructor() {
        if (!RecordTypesMap._instance) {
            this.options = {};
            RecordTypesMap._instance = this;
        }

        return RecordTypesMap._instance;
    }

    static getInstance(){
        return this._instance;
    }

    static postTypes = [];
    
    static recordTypes = {
        donors : ["fc","ff","fg","fi","fob","enti_religiosi","enti_internazionali","pa","associazioni","aziende"],
        opportunities : ["grant","opportunita"],
        content : ["faq","smara_case","smara_chart","smara_news","smara_rank","smara_tool","tutorial"]
    };
    
    static getRecordTypeByPostType(postType){
        var result = false;
        _.forEach(RecordTypesMap.recordTypes, function (value, key) {
            if(value.indexOf(postType) >= 0) result = key;
        });
        return result;
    }
    
}

export default RecordTypesMap