class PackManager {
    get instance() {
        return this._instance;
    }

    #pack
    #packSlug

    constructor(pack) {
        if (!PackManager._instance) {
            PackManager._instance = this;
        }

        this.#pack = pack;
        //console.log(Config._instance)
        return PackManager._instance;
    }

    static getInstance() {
        return this._instance;
    }


    static availablePacks = {
        ADVISOR: {
            path: 'advisor',
            rootState: 'opportunitiesDashboard'
        },
        CUSTOMER: {
            path: 'customer',
            rootState: 'explore'
        }
    }


    get packSlug() {
        let res = "";
        try {
            _.forEach(Object.keys(PackManager.availablePacks), (k) => {
                //console.log(k,PackManager.getInstance().pack.path.trim().toUpperCase())
                if (PackManager.getInstance().pack.path.trim().toUpperCase() === k) {
                    res = k;
                }
            })
            if (res === "") {
                throw "pack non disponibile";
            } else {
                return res
            }
        } catch (e) {
            console.error(e)
        }
    }

    set pack(value) {
        this.#pack = value;
    }

    get pack() {
        return this.#pack;
    }
}

export default PackManager