import UserMeta from "./../UserMeta";
import Config from "../Config";

class BeamerConnector {

    static UserInfo = {}


    constructor() {

        if (!BeamerConnector._instance) {
            BeamerConnector._instance = this;


            BeamerConnector.UserInfo = {
                "user_id": UserMeta.userMeta.uid,
                "trial": UserMeta.isTrial,
                "account_type": UserMeta.userMeta.account_type,
                "plan": UserMeta.getInstance().userProduct.product.name.toUpperCase(),
                "filter": `${UserMeta.getInstance().userProduct.product.name.toUpperCase()}${UserMeta.isTrial ? ";TRIAL" : ""}`,
                user_firstname: UserMeta.userMeta.first_name,
                user_lastname: UserMeta.userMeta.last_name,
                user_company: Config.options.userMeta.smara_nome_onp,
                user_website: Config.options.userMeta.smara_website_onp,
                user_team: Config.options.userMeta.smara_team_name,
                user_city: Config.options.userMeta.smara_legal_address.city,
                "user_email": UserMeta.userMeta.email

            }


            window.beamer_config = {
                product_id: process.env.BEAMER_KEY,
                language: 'IT',
                ...BeamerConnector.UserInfo
            };


            (function (window, document, scriptUrl, params = '', result) {
                window = document.getElementsByTagName('head')[0];
                result = document.createElement('script');
                result.defer = true;
                result.src = scriptUrl + params;
                window.appendChild(result);
            })(window, document, 'https://app.getbeamer.com/js/beamer-embed.js');

        } else {
            console.log("class already inizialized")
        }


        return BeamerConnector._instance;

    }
}

export default BeamerConnector