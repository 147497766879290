import Config from "./Config";

const _ = require('lodash');
import EventEmitter from "eventemitter3";

class Deus {

    static EE = new EventEmitter();
    static triggers = [];

    static state = {
        home: false,
        loading: false,
        progress: 0,
        flow: [],
        history: [],
        triggers: [],
        subview: false
    }


    static $on(event, handler, context) {
        Deus.EE.addListener(event, (args) => {
            //console.log("ricevuto evento", args)
            handler(event, args)
        }, context);
    }

    static $emit(event, ...args) {
        //console.log("emetto evento", event, args);
        Deus.EE.emit(event, args);
    }

    static $removeListener(event) {
        Deus.EE.removeListener(event)
    }

    static addTrigger(func) {
        //console.log('add trigger')
        Deus.triggers.push(func)
    }

    static processTriggers() {
        //console.log('process triggers', Deus.triggers)

        while (Deus.triggers.length > 0) {
            (Deus.triggers.shift())();
        }

        //Deus.clearTriggers();
    }

    static clearTriggers() {
        //console.log('clear triggers')
        Deus.triggers = [];
    }

};

export default Deus