
const moment = require('moment');

import Config from "./Config";
import Api from "./Api";
import Utilities from "./Utilities";
import Deus from "./Deus";

import Avatar from "./Dictionary/Avatar";
import ProspectFlags from "./Dictionary/ProspectFlags";
import PartnershipFlags from "./Dictionary/PartnershipFlags";
import RecordTypesMap from "./Dictionary/RecordTypesMap";
import Toast from "./Dictionary/Toast";
import OnBoarding from "./Dictionary/OnBoarding";
import _ from "lodash";
import {ACLStatusCode} from "../types/acl.type";


class UserMeta {

    static PERMISSIONS = {
        "READ": 0,
        "WRITE": 1,
        "ADMIN": 2
    };
    static actionsRules = {
        "owner": {
            "view": UserMeta.PERMISSIONS.ADMIN,
            "follow": UserMeta.PERMISSIONS.ADMIN,
            "prioritize": UserMeta.PERMISSIONS.ADMIN,
            "flag": UserMeta.PERMISSIONS.ADMIN,
            "like": UserMeta.PERMISSIONS.ADMIN,
            "archive": UserMeta.PERMISSIONS.ADMIN,
            "print": UserMeta.PERMISSIONS.ADMIN,
            "looking_for_partner": UserMeta.PERMISSIONS.ADMIN,
            "partner_contact": UserMeta.PERMISSIONS.ADMIN,
        },
        "manager": {
            "view": UserMeta.PERMISSIONS.ADMIN,
            "follow": UserMeta.PERMISSIONS.ADMIN,
            "prioritize": UserMeta.PERMISSIONS.ADMIN,
            "flag": UserMeta.PERMISSIONS.ADMIN,
            "like": UserMeta.PERMISSIONS.ADMIN,
            "archive": UserMeta.PERMISSIONS.ADMIN,
            "print": UserMeta.PERMISSIONS.ADMIN,
            "looking_for_partner": UserMeta.PERMISSIONS.ADMIN,
            "partner_contact": UserMeta.PERMISSIONS.ADMIN,
        },
        "member": {
            "view": UserMeta.PERMISSIONS.ADMIN,
            "follow": UserMeta.PERMISSIONS.WRITE,
            "prioritize": UserMeta.PERMISSIONS.ADMIN,
            "flag": UserMeta.PERMISSIONS.ADMIN,
            "like": UserMeta.PERMISSIONS.WRITE,
            "archive": UserMeta.PERMISSIONS.READ,
            "print": UserMeta.PERMISSIONS.ADMIN,
            "looking_for_partner": UserMeta.PERMISSIONS.WRITE,
            "partner_contact": UserMeta.PERMISSIONS.WRITE,
        }
    };
    static actions = {
        "view": {
            writeTo: "viewed",
            callback: function () {
                /**
                 * ajax call mando quale id del post ho appena visto, mi ritorna un contatore
                 * che mi dice quanti post nuovi posso ancora vedere e
                 * quali posso vedere perchè già sbloccati
                 */
                UserMeta.updateViewedPostList();
            },
            team: false, // credo sia obsoleta, dobbiamo verificare
            rules: ['add', 'remove'],

        },
        "follow": {
            writeTo: "followed",
            callback: null
        },
        "prioritize": {
            writeTo: "priorities",
            callback: null
        },
        "flag": {
            writeTo: "flagged",
            callback: null
        },
        "looking_for_partner": {
            writeTo: "looking_for_partner",
            callback: function (post, actionType) {

                var postMeta = UserMeta.getUserMetaForPost(post.ID, post.post_type);
                //console.log(postMeta)
                if (!postMeta.liked) {

                    UserMeta.callbackPool.push(function () {
                        return new Promise(function (resolve, reject) {

                            UserMeta.setUserMetaForPost("like", {info: "for_me"}, post, "add");
                            resolve();
                        })
                    });
                }
                UserMeta.callbackPool.push(function () {
                    return new Promise(function (resolve, reject) {
                        Api.get({
                            action: 'smara_community_add_partner_notification',
                            object_id: post.ID,
                            team_id: UserMeta.userMeta['team.id']
                        }).then(function (response) {
                            resolve();
                        });

                    })
                });
            }
        },
        "partner_contact": {
            writeTo: "partner_contact",
            callback: null
        },
        "like": {
            writeTo: "liked",
            callback: function (post, actionType) {

                if (RecordTypesMap.getRecordTypeByPostType(post.post_type) === 'donors') {
                    UserMeta.callbackPool.push(function () {
                        return new Promise((resolve, reject) => {
                            UserMeta.setUserMetaForPost("follow", {info: 1}, post, actionType);
                            resolve();
                        })
                    });
                }
            }
        },
        "archive": {
            writeTo: "archived",
            callback: null
        },
        /*"annotate": {
            writeTo: "annotated",
            callback: null
        },*/
        "print": {
            writeTo: "printed",
            callback: function () {
                window.print();
            }
        }
    };

    // pool di azioni da eseguire in concatenamento dopo aver settato un meta.

    static callbackPool = [];
    static processActive = false;

    hash;
    activeSession; // @deprecated
    switch_subscription_url;
    community;
    userLimits;
    //userMembershipRole;
    userMemberships;
    userSubscriptionDetailUrl;
    //userSubscriptionStatus;
    userEarlyAdopter;
    userHasEnabler;
    userTrialDaysLeft;
    userTrialEnd;
    userMeta;
    userPreferences;

    // contiene le informazioni di profilazione che sono state impostate per tutto il team
    teamPreferences;


    isTrial;
    isCustomerTrial;
    trialAvatar;
    isEnte;

    hasAvatar;

    static get subscription() {
        // Check if user has a subscription
        if (UserMeta.getInstance().userProduct && UserMeta.getInstance().userProduct.product) return UserMeta.getInstance().userProduct.product;
        else return false;
    }


    static get upgradeLandingPage() {
        switch (UserMeta.subscription.name) {
            case "basic":
                return "upgradeLandingPlus";
            default: return "upgrade";
        }
    }

    static get isTrial() {
        return UserMeta.getInstance().isTrial
    }

    static get isBetaTester(){
        // Check if user is member of the beta tester group
        if(_.isUndefined(process.env.BETA_TESTER_TEAMS)) return false;
        const betaTesterTeams = process.env.BETA_TESTER_TEAMS.split(',');
        return betaTesterTeams.includes(UserMeta.getInstance().userProduct.team.ID.toString());
    }

    /**
     *
     * @return string
     */
    static get userIdentifiedAs() {
        return UserMeta.userMeta.smara_try_granter_for.toString() || '1';
    }

    static get isUserIdentifiedAsOnp(){
        return UserMeta.userIdentifiedAs === '1';
    }
    static get isUserIdentifiedAsAdvisor(){
        return UserMeta.userIdentifiedAs === 'consulente';
    }

    static get userMeta() {
        return UserMeta.getInstance().userMeta
    }


    static get hasPreferences() {
        return (UserMeta.getInstance().userMeta.smara_onboarding_steps && UserMeta.getInstance().userMeta.smara_onboarding_steps.complete);
    }

    static parsePreferences(preferences) {
        _.forEach(preferences, function (actions) {
            _.forEach(actions, function (value) {
                if (value.info)
                    value.info = JSON.parse(value.info);
            });
        });
        //console.log(preferences)
        return preferences
    }


    constructor() {
        if (!UserMeta._instance) {
            UserMeta._instance = this;
        }

        return UserMeta._instance;
    }

    static get teamPreferences() {
        const meta = UserMeta.userMeta;

        const resultsMap = {
            smara_area_geografica: [],
            smara_focus_geografico: [],
            smara_focus_geografico_internazionale: [],
            smara_topic: [],
            smara_trend_topic: [],
            smara_legal_address: {},
            smara_legal_status: [],
            smara_enti_ammissibili_tipologie: [],
            smara_enti_ammissibili_qualifiche: [],
            smara_beneficiari_plus: [],
            smara_cause: []
        }

        const results = {}

        _.forEach(resultsMap, (item, key) => {
            if (!_.isUndefined(meta[key])) {
                results[key.split("smara_")[1]] = meta[key];
            }
        });

        return results;


    }

    init() {

        /**
         *
         * @type {boolean|string}
         */
        this.account_type = (function () {
            let account_type = "CUSTOMER";
            if (!Config.options.u) return account_type; // wrap errore backand
            _.forEach(Config.options.u.products, function (team) {
                if (team.product) {
                    account_type = team.product.type;
                }
            });
            return account_type;
        })()

        this.userPreferences = _.merge(
            (function () {
                let meta = {}

                _.forEach(UserMeta.actions, function (value) {
                    meta[value.writeTo] = {}
                });
                return meta;
            })(), UserMeta.parsePreferences(Config.options.userPreferences))

        this.hash = Config.options.teamPreferencesHash;
        this.activeSession = Config.options.active_session; // @deprecated
        this.switch_subscription_url = Config.options.switch_subscription_url;
        this.userLimits = Config.options.userLimits;
        //this.userMembershipRole = Config.options.userMembershipRole;
        //this.userMemberships = Config.options.userMemberships[0]; // TODO questo va cambiato tutto
        this.userProduct = Config.options.u.products[0];
        this.userSubscriptionDetailUrl = Config.options.userSubscriptionDetailUrl;
        //this.userSubscriptionStatus = Config.options.userSubscriptionStatus;
        this.userEarlyAdopter = parseInt(Config.options.earlyAdopter) ?? false;
        this.userHasEnabler = Config.options.enabler;
        this.userTrialDaysLeft = Config.options.userTrialDaysLeft;
        this.userTrialEnd = moment().add(Config.options.userTrialDaysLeft, 'days').format("dddd DD MMM");

        this.userNPS = Config.options.u ? Config.options.u.nps : {};
        this.userPMF = Config.options.u ? Config.options.u.pmf : {};
        this.userPTR = Config.options.u ? Config.options.u.ptr : {};

        const seed = [
            Config.options.userMeta.first_name,
            Config.options.userMeta.last_name,
            Config.options.userMeta.user_role
        ].join("")
        this.userAvatar = {
            seed: seed,
            url: `https://api.dicebear.com/7.x/initials/svg?seed=${seed}`
        }


        this.community = Config.options.community;
        this.userMeta = Config.options.userMeta;


        //console.log(this.userMeta,Config.options.u)
        //this.userPreferences = {};

        //console.log(typeof  this.userTrialDaysLeft, this.userTrialDaysLeft)

        //console.log(Config.options.isTrial)
        this.isTrial = Config.options.isTrial;
        this.isCustomerTrial = Config.options.isCustomerTrial;
        this.trialAvatar = Config.options.trialAvatar;
        this.isEnte = parseInt(Config.options.isEnte);

        this.hasAvatar = Avatar.dictionary[Config.options.trialAvatar];

        UserMeta.checkUserPreferences();
        this.userMeta.smara_onboarding_checklist.__status = UserMeta.validateOnboarding();
    }

    static getInstance() {
        return this._instance;
    }


    updateUserPreferences() {
        return Utilities.debounce(function () {
            Api.post({
                preferences: JSON.stringify(UserMeta.getInstance().userPreferences),
                action: 'set_preferences'
            }, true).then(function (response) {
                //console.log(response)
            });

        }, 800)
    };

    static checkUserPreferences() {
        return new Promise((resolve, reject) => {
            if (!UserMeta.processActive) {
                return Api.getV2({
                    action: 'get_preferences',
                    hash: UserMeta.getInstance().hash
                }, true).then(function (response) {

                        UserMeta.checkHash(response.data);
                        setTimeout(function () {
                            UserMeta.checkUserPreferences();
                        }, 3 * 10000);
                        resolve();
                    }
                ).catch(e => {
                    if (e.response && e.response.status && e.response.status === ACLStatusCode.UNAUTHORIZED) {
                        Deus.$emit('openModal', {key: 'app:session_fail'})
                    } else {
                        Deus.$emit('openModal', {key: 'app:connection_fail'})
                    }
                })
            } else {
                console.warn("coda piena");
                setTimeout(function () {
                    UserMeta.checkUserPreferences();
                    resolve();
                }, 1 * 1000);
            }
        })
        /* return Utilities.deboucedPromise(function () {


         }, 600)*/
    }


    static updateViewedPostList() {
        return Utilities.debounce(function () {
            Api.post({
                action: 'get_user_limits'
            }, true).then(function (response) {
                //console.log(response.data.data.limits)
                UserMeta.getInstance().userLimits.limits = response.data.data.limits;
            });
        }, 100)
    }

    static processCallbackPool(post, actionType) {
        //console.log(UserMeta.getInstance().callbackPool[0],UserMeta.getInstance().processActive);

        if (UserMeta.callbackPool.length > 0) {
            UserMeta.processActive = true;
            const func = UserMeta.callbackPool[0];

            if (_.isFunction(func)) {
                func(post, actionType).then(function () {

                    UserMeta.processCallbackPool(post, actionType);
                });
            }
            UserMeta.callbackPool.shift();
        } else {
            UserMeta.processActive = false;
        }
    };

    static checkSession(remoteData) {
        //console.log(remoteData.active_session, remoteData.user_session)
        return _.isEqual(remoteData.user_session, remoteData.active_session);
    };

    static checkHash(remoteData) {

        // questa funzione viene chiamata dal get ping se da remoto non risultano cambiamenti mi interrompo
        if (_.isEqual(remoteData.hash, UserMeta.getInstance().hash)) return;

        // per disallineamento timing
        if (!remoteData.preferences) return;


        // aggiorno l'hash
        UserMeta.getInstance().hash = remoteData.hash;

        // console.log(remoteData.preferences)
        if (!remoteData.update) return;

        // se ci sono stati cambiamenti aggiorno le preferenze con quelle che mi arrivano da remoto
        UserMeta.getInstance().userPreferences = UserMeta.parsePreferences(remoteData.preferences);

        // lo faccio sapere a tutte le view
        Deus.$emit('updateDatasourcePreferences');

        //m notifica per le viste dinamiche che devo aggiornarsi
        Deus.$emit('notifyDatasourceChanges');
    };

    static setUserMetaForPost(action, data, post, actionType) {

        //console.log("set user meta for post")

        const recordType = RecordTypesMap.getRecordTypeByPostType(post.post_type);
        const post_id = post.ID;

        return new Promise((resolve, reject) => {

            /**
             *  Preparo il toast
             */

            let toastMessage = {
                display: true,
                title: post_id,
                action: action
            }


            let userMetaForPost = UserMeta.getUserMetaForPost(post_id, post.post_type);
            let actionProperties = UserMeta.actions[action];
            if (!actionType) {
                actionType = _.isEqual(userMetaForPost[actionProperties.writeTo], data.info) ? "remove" : "add";
            }

            /**
             *  Controllo di errore su post id
             */

            if (!post_id) reject();


            /**
             *  Controllo dei permessi
             */
            const permission = UserMeta.getPermissionForUserMeta(post.ID, post.post_type)[action];

            if (!permission.canEdit) {
                toastMessage.body = Toast.getBody("permission", action + '.' + actionType)
                Deus.$emit('addToast', toastMessage);
                return reject();
            }


            data.id = post_id;


            this.setLocalUserMetaForPost(action, data, post, actionType);

            toastMessage.body = Toast.getBody(recordType, action + '.' + actionType);


            switch (actionType) {
                case "add":
                    // se non ci sono preferenze per questo post id allora alla add preparo l'oggetto per averne uno vuoto
                    if (!UserMeta.getInstance().userPreferences[actionProperties.writeTo][post_id]) {
                        UserMeta.getInstance().userPreferences[actionProperties.writeTo][post_id] = {}
                    }
                    UserMeta.getInstance().userPreferences[actionProperties.writeTo][post_id].info = data;
                    UserMeta.getInstance().userPreferences[actionProperties.writeTo][post_id].user_id = UserMeta.getInstance().userMeta.uid
                    break;
                case "remove" :
                    delete UserMeta.getInstance().userPreferences[actionProperties.writeTo][post_id]
                    break;

            }

            // mchecklist onboarding
            // non parte se non è inizializzato su backand
            // non viene aggiornato se l'onboarding è completo
            if (
                !UserMeta.getInstance().userMeta.smara_onboarding_checklist.__dismissed &&
                !UserMeta.getInstance().userMeta.smara_onboarding_checklist.__status.completed)
                UserMeta.updateOnboarding(recordType, action, actionType);

            //else return

            UserMeta.callbackPool.push(function () {
                    return new Promise((resolve, reject) => {
                        Deus.$emit('updateDatasourcePreferences');
                        Api.post({
                            action: 'user_action',
                            object_id: post_id,
                            actionName: action,
                            hash: UserMeta.getInstance().hash,
                            actionType: actionType,
                            info: JSON.stringify(data)
                        }, true).then(function (response) {
                            if (!response.data.success) {
                                // TODO impostare anche tipo di errore
                                toastMessage.body = Toast.getBody("permission", "denied")
                            } else {

                                // log event to elastic
                                Api.logEvent(action, actionType, data);

                                UserMeta.getInstance().hash = response.data.data.hash;
                                // check hash and preferences
                                UserMeta.callbackPool.push(function () {
                                    return new Promise(function (resolve) {
                                        UserMeta.checkUserPreferences();
                                        resolve();
                                    })
                                })
                                //UserMeta.getInstance().hash = response.data.data.hash;

                            }

                            if (toastMessage.body)
                                Deus.$emit('addToast', toastMessage);

                            resolve();
                        });
                    })
                }
            );


            if (actionProperties.callback !== null)
                UserMeta.callbackPool.push(function () {
                    return new Promise((resolve, reject) => {
                        actionProperties.callback(post, actionType);
                        resolve();
                    })
                });


            if (!UserMeta.processActive) {
                return UserMeta.processCallbackPool(post, actionType);
            }


        })


    };

    static getUserMetaForPost(post_id, post_type) {
        var meta = {}

        _.forEach(UserMeta.actions, function (value, key) {
            meta[value.writeTo] = UserMeta.getTagForPost(value.writeTo, post_id, post_type)
        });
        return meta;
    };

    /**
     *
     * @param action
     * @param data
     * @param post
     * @param actionType
     *
     * Funzione che aggiorna le preferenze locali (solo in UserMeta e non in DB) dell'utente
     * Usata per facilitare la gestione delle preferenze in app e aggiornare le viste in tempo reale
     * Es. Algolia (Archiviati)
     *
     */
    static setLocalUserMetaForPost(action, data, post, actionType) {
        const recordType = RecordTypesMap.getRecordTypeByPostType(post.post_type);
        const post_id = post.ID;

        let userMetaForPost = UserMeta.getUserMetaForPost(post_id, post.post_type);
        let actionProperties = UserMeta.actions[action];
        if (!actionType) {
            actionType = _.isEqual(userMetaForPost[actionProperties.writeTo], data.info) ? "remove" : "add";
        }

        data.id = post_id;

        switch (actionType) {
            case "add":
                // se non ci sono preferenze per questo post id allora alla add preparo l'oggetto per averne uno vuoto
                if (!UserMeta.userMeta[actionProperties.writeTo][post_id]) {
                    UserMeta.userMeta[actionProperties.writeTo][post_id] = {}
                }
                UserMeta.userMeta[actionProperties.writeTo][post_id].info = data;
                UserMeta.userMeta[actionProperties.writeTo][post_id].user_id = UserMeta.getInstance().userMeta.uid
                break;
            case "remove" :
                delete UserMeta.userMeta[actionProperties.writeTo][post_id]
                break;
        }

        Deus.$emit('updateUserMetaLocal', {action: action, data: data, post: post, actionType: actionType});
    }

    static getTagForPost(tag, post_id, post_type) {

        // sovrascrive la preferenza per i flag
        var recordType = RecordTypesMap.getRecordTypeByPostType(post_type)

        if (tag === "flagged")
            return UserMeta.getInstance().userPreferences.flagged.hasOwnProperty(post_id) ? UserMeta.getInstance().userPreferences.flagged[post_id].info.info : ProspectFlags.getFlagsForType(recordType)[0].value;

        if (tag === "looking_for_partner" && post_type && RecordTypesMap.getRecordTypeByPostType(post_type) === "opportunities")
            return UserMeta.getInstance().userPreferences.looking_for_partner.hasOwnProperty(post_id) ? UserMeta.getInstance().userPreferences.looking_for_partner[post_id].info.info : PartnershipFlags.getFlagsForType(recordType)[0].slug;

        if (UserMeta.getInstance().userPreferences[tag] && UserMeta.getInstance().userPreferences[tag].hasOwnProperty(post_id))
            return UserMeta.getInstance().userPreferences[tag][post_id].info.info;


        return false;
    };

    static getPermissionForUserMeta(post_id, post_type) {
        /**
         *  {
         *      isMine : true | false,
         *      canEdit : true | false
         *  }
         */

        let permission = {};

        _.forEach(UserMeta.actions, function (value, key) {
            var canEdit, isMine;
            var ruleForAction = UserMeta.actionsRules[UserMeta.getInstance().userMeta.user_role][key];


            if (UserMeta.getInstance().userPreferences[value.writeTo] && UserMeta.getInstance().userPreferences[value.writeTo].hasOwnProperty(post_id)) {

                var metaForPost = UserMeta.getInstance().userPreferences[value.writeTo][post_id];

                isMine = parseInt(metaForPost.user_id) === UserMeta.getInstance().userMeta.uid;
                canEdit = ruleForAction === UserMeta.PERMISSIONS.ADMIN || isMine && ruleForAction === UserMeta.PERMISSIONS.WRITE


            } else {
                canEdit = ruleForAction === UserMeta.PERMISSIONS.ADMIN || ruleForAction === UserMeta.PERMISSIONS.WRITE;
                isMine = false
            }

            permission[key] = {
                isMine: isMine,
                canEdit: canEdit
            }
        });

        return permission;

    };

    static updateOnboarding(recordType, action, actionType) {
        // se non è una aggiunta ritorno
        if (actionType !== "add") return;

        // per checklist on boarding;

        if (!UserMeta.getInstance().userMeta.smara_onboarding_checklist[recordType][action]) {

            var $widget = $('#onboarding-center__widget-btn .btn-cta-success');
            var $badge = $('#onboarding-center__widget-btn .badge');

            $badge.removeClass('animate__animated animate__heartBeat');
            $widget.removeClass('animate__animated animate__bounce');
            UserMeta.getInstance().userMeta.smara_onboarding_checklist[recordType][action] = true;

            //console.log(OnboardingDictionary.checklist);
            UserMeta.getInstance().userMeta.smara_onboarding_checklist.__status = UserMeta.validateOnboarding();


            setTimeout(function () {
                $badge.addClass('animate__animated animate__heartBeat');
                $widget.addClass('animate__animated animate__bounce');
            }, 1000)

            Api.post({
                action: 'update_onboarding_checklist',
                info: JSON.stringify(UserMeta.getInstance().userMeta.smara_onboarding_checklist)
            }).then(
                function (response) {
                    console.log(response);
                }
            )
        }
    };

    static completeOnboarding() {
        UserMeta.getInstance().userMeta.smara_onboarding_checklist.__status = UserMeta.validateOnboarding();

        UserMeta.getInstance().userMeta.smara_onboarding_checklist.__dismissed = true;
        Api.post({
            action: 'update_onboarding_checklist',
            info: JSON.stringify(UserMeta.getInstance().userMeta.smara_onboarding_checklist)
        }).then(
            function (response) {
                console.log(response);
            }
        )

    };

    static validateOnboarding() {
        var status = {
            steps: [],
            completed: false,
            completion: 0
        }

        _.forEach(OnBoarding.checklist, function (value, key) {
            // console.log(value.validationRules,WPUserMeta.userMeta.smara_onboarding_checklist);
            var validity = false;
            _.forEach(value.validationRules, function (rule) {
                if (Utilities.getPropertyByStringPath(UserMeta.getInstance().userMeta.smara_onboarding_checklist, rule) && !validity) {
                    validity = true
                    status.completion++
                    status.steps[key] = true;
                } else {
                    status.steps[key] = false;
                }
            });

            value.validity = validity;
            value.active = !validity && value.belongTo >= 0 && OnBoarding.checklist[value.belongTo].validity === true;
        });

        status.counter = OnBoarding.checklist.length - status.completion;
        status.completed = status.counter === 0;
        status.percentage = Math.round(status.completion * 100 / OnBoarding.checklist.length);

        //console.log(status);

        return status;


    }


    static getSuggestedOpportunities(){
        return Api.getV2({
            action: 'get_suggested_opportunities',
            suggested_minimum_should_match: 3,
        }, true).then(function (response) {
            return response.data;
        })
    }

}


export default UserMeta