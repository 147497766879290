import AppStates from "../../modules/AppStates";
import Utilities from "../../modules/Utilities";
import _ from "lodash";
import PackManager from "../../modules/Account/PacksManager";
import Deus from "../../modules/Deus";
import CustomSerp from "../../modules/CustomSerp";

(function () {
    'use strict';

    function updateRoutes($stateProvider){
        const routes = CustomSerp.routes;
        _.forEach(routes,(settings,type)=>{

            const names = [];
            _.forEach(settings, (serp)=>{
                let slug = Utilities.slugify(serp.label)
                let name = `${type}.${slug}`;
                let i = 0;
                while(names.indexOf(name) >= 0){
                    i++;
                    name = name + (i);
                    slug = slug + (i);

                }
                names.push(name)
                //console.log(names,slug)

                const {stateRegistry:{states}} = $stateProvider;

                const {parent,detail} = AppStates.createStateForCustomSerp(serp,name,slug,type);
                // check se la route esiste
                if(!states.hasOwnProperty(parent.name)){
                    $stateProvider.state(parent)
                }
                //console.log(detail.name)
                if(!states.hasOwnProperty(detail.name)){
                    $stateProvider.state(detail)
                }
            })
        })
    }


    angular.module('smara', [
        'ui.router',
        'headroom',

        'summernote',
        'youtube-embed',
        //'duScroll',
        //'smara:shared',
        'slickCarousel',
        'ngAnimate',
        'ngSanitize',
        'ui.select'
    ]).filter('htmlSafe', ['$sce', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }]).config([
        '$httpProvider',
        '$stateProvider',
        '$urlRouterProvider',
        '$locationProvider',
        '$sceDelegateProvider',
        function ($httpProvider, $stateProvider, $urlRouterProvider, $locationProvider,$sceDelegateProvider) {

            $sceDelegateProvider.resourceUrlWhitelist([
                // Allow same origin resource loads.
                'self',
                // Allow loading from our assets domain. **.
                process.env.WP_URL + '/**'
            ]);

            $httpProvider.defaults.withCredentials = true;


            // invalidazione della cache sui template html
            $httpProvider.interceptors.push(function() {
                return {
                    request: function(config){
                        if(config.url.includes('/ng-templates/')){
                            //console.log(config.url)
                            Object.assign(config.headers,{"Cache-Control": "no-cache, must-revalidate"});
                        }

                        return config;
                    }
                }
            })



            const appStates = new AppStates();

            // aggiungo gli stati al routing
            _.forEach(appStates.init(), function (state, key) {
                $stateProvider.state(state);
            });


            // quando non ci sono stati precisi
            $urlRouterProvider.otherwise(function ($injector) {
                var $state = $injector.get('$state');
                $state.go(PackManager.getInstance().pack.rootState);
            });



            CustomSerp.init().then(()=>{
                updateRoutes($stateProvider)
            });

            Deus.$on("customSerpUpdate",(e,[serps])=>{
                updateRoutes($stateProvider)
            })

            $locationProvider.html5Mode({
                enabled: true,
                // requireBase: false,
                // rewriteLinks: false
            });


        }])
})();